.top-bar-dropdown {
  &-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 8px;
    top: 54px;
    width: 212px;
    background: $panelColor;
    border-radius: 8px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    border: 1px solid $borderGray;
    box-sizing: border-box;
    z-index: 999;
  }

  &-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
  }

  &-toggle-icon {
    position: relative;
    width: 10px;
    color: $mainGray;
    font-size: 16px;
    margin-bottom: 2px;

    &.icon-new-releases {
      color: $mainOrange;
    }
  }
}