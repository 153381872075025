.call-parking-wallboard {
  width: 100%;
  height: 100%;
  padding: 16px 0;
  background-color: $panelColor;

  .service-tab-header {
    column-gap: 16px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      color: $black;
    }

    .select-call-parking {
      max-width: 220px;
    }
  }
}