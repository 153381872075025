.softphone {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  margin-right: 20px;
  width: 506px;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  position: relative;

  @media(max-width: $laptopPoint) {
    width: 406px;
  }

  @include tablet-media {
    width: 336px;
    margin-right: 10px;
  }

  @media(max-width: $tabletLargePoint) {
      border-radius: 0;
      margin-right: 0;
      max-width: 100%;
      width: 100%;
  }

  .integrations-popup {
    .base-popup-content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}