.caller-id-select-search-input {
  position: relative;
  width: 100%;
  height: 35px;
  font-size: 11.9px;

  .search {
    width: 100%;
    height: 100%;
    font-size: 12px;
    color: $darkGray;
    padding: 5px 48px 5px 5px;
    outline: none;
    border: none;
    text-overflow: ellipsis;
    font-weight: 600;

    &::placeholder {
      font-weight: 400;
    }
  }

  .actions {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 48px;
    height: 18px;
    top: calc(50% - 9px);
    right: 0;

    .search-clear {
      font-size: 18px;
      color: $mainGray;
      cursor: pointer;
    }

    .magnifier {
      font-size: 18px;
      color: $mainBlue;
      cursor: default;
    }
  }
}