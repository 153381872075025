.call-parking-item-table-row {
  border-top: 1px solid $borderGray;
  position: relative;

  &-cell {
    padding: 11px;
    font-size: 12px;
    font-weight: 500;
    color: $darkGray;
  }

  .call-btn {
    &:hover {
      background-color: $mainGreen !important;
    }
  }
}