.mute-ring-button.base-button {
  background: $white;
  color: $darkModeBlack;
  font-size: 14px;
  font-weight: 600;
  height: 36px;
  border: none !important;

  @include is-tablet-or-sm-mobile {
    height: 32px;
  }

  .icon {
    color: $darkModeBlack;
  }

  &:hover {
    background: rgba($white, 0.92);
  }

  &:active {
    background: rgba($white, 0.86);
  }

  &.mute-ring-button-active {
    color: $white;
    background: rgba($white, 0.04);

    .icon {
      color: $white;
    }

    &:hover {
      background: rgba($white, 0.08);
    }

    &:active {
      background: rgba($white, 0.12);
    }
  }
}