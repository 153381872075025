.crm-contact-expand {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .info-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .data {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: $darkGray;

      @media(max-width: $phonePoint) {
        font-size: 1.45em;
        line-height: 1em;
      }
    }

    .label {
      display: inline-block;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      margin-top: 6px;
      color: $textGray;

      @media(max-width: $phonePoint) {
        font-size: 1.1em;
        line-height: 1.1em;
        margin-top: 0;
      }
    }
  }

  .general-info-wrapper, .card-wrapper, .tickets-wrapper {
    margin-top: 16px;
    width: 100%;

    .info-group {
      .data {
        font-size: 14px;
        padding-bottom: 3px;

        @media(max-width: $phonePoint) {
          font-size: 1.2em;
        }
      }

      .label {
        margin-top: 0;

        @media(max-width: $phonePoint) {
          font-size: 1.1em;
        }
      }
    }
  }

  .general-info-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: 50% 50%;
    max-height: 120px;
    height: 100%;

    @media(max-width: $phonePoint) {
      height: fit-content;
    }

    .info-group {
      margin-right: 10px;
      flex-basis: 26%;
      margin-bottom: 10px;

      .data {
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      @media(max-width: $phonePoint) {
        margin-right: 0.6em;
        margin-bottom: 1em;
      }

      &:nth-child(3n) {
        margin-bottom: 0;
      }
    }
  }

  .card-wrapper {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 12px;
    background-color: #F7F9FF;
    padding: 12px;

    @media(max-width: $phonePoint) {
      padding: 1em;
      border-radius: 1em;
    }

    .crm-card-header {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .card-title {
        font-weight: 600;
        font-size: 16px;
        color: $darkGray;

        @media(max-width: $phonePoint) {
          font-size: 1.45em;
        }
      }

      .total-number {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: $mainBlue;
        color: $white;
        font-weight: 600;
        font-size: 16px;
        margin-left: 8px;

        @media(max-width: $phonePoint) {
          width: 1.4em;
          height: 1.4em;
          font-size: 1.45em;
        }
      }
    }

    .info-group {
      margin-top: 16px;

      @media(max-width: $phonePoint) {
        margin-top: 0.7em;
      }

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .tickets-wrapper {
    .crm-card-header {
      max-width: 135px;

      @media(max-width: $phonePoint) {
        max-width: 70%;
      }
    }
  }

  &.inline {
    flex-direction: row;
    flex-wrap: wrap;

    .info-group {
      .data {
        font-size: 12px;
        line-height: 12px;
      }

      .label {
        font-size: 9px;
        line-height: 9px;
      }
    }

    > div {
      margin-top: 8px;
    }

    .general-info-wrapper {
      width: 405px;
      max-height: 105px;
      height: 100%;

      @media(max-width: $laptopPoint) {
        width: 348px;
      }

      @media(max-width: $laptopMediumPoint) {
        width: 100%;
      }
    }

    .card-wrapper {
      width: 230px;
      margin-right: 16px;

      @media(max-width: $laptopPoint) {
        width: 188px;
      }

      @media(max-width: $laptopMediumPoint) {
        width: calc(50% - 16px);
      }

      @media(max-width: $phonePoint) {
        width: 100%;
        margin-right: 0.6em;
      }

      .crm-card-header {
        .card-title {
          font-size: 12px;
        }

        .total-number {
          width: 18px;
          height: 18px;
          font-size: 12px;
        }
      }
    }
  }
}