.queues-tab {
  height: 100%;
  position: relative;

  .queues-title {
    padding: 0 16px 16px 16px;
  }
  &.loading {
    position: relative;

    .overlay {
      display: block;
      background-color: rgba($panelColor, 0.5);
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0;
      right: 0;
      z-index: 100;
    }

    > .base-spinner {
      z-index: 200;
    }
  }

  .queues-wrapper {
    height: calc(100% - 44px);
    width: 100%;
  }

  .queues-not-found {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $darkGray;
    font-style: normal;
    font-weight: 600;

  }

  .queues-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(316px, 1fr));
    grid-gap: 16px 16px;
    padding-left: 16px;
    padding-right: 20px;
    padding-bottom: 16px;

    @media(max-width: $phonePoint) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    .queue-item {
      display: inline-flex;
      border-radius: 12px;
      border: 1px solid $borderGray;
      height: 112px;
      color: $darkGray;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      padding: 1px;
      flex-direction: column;
      box-sizing: border-box;

      .switch-wrapper {
        display: flex;
        align-items: center;
        position: relative;
        width: 100%;
        margin: 0;
        padding: 14px 16px 0px 16px;
        cursor: pointer;

        .icon {
          font-size: 24px;
          color: $mainGray;
          position: absolute;
          top: 14px;
          right: 16px;
          margin: 0;

          &:before {
            margin: 0;
          }
        }

        .name {
          display: block;
          padding-left: 16px;
          padding-right: 32px;
          width: 100%;
          height: 24px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          //display: -webkit-box;
          //-webkit-line-clamp: 2;
          //-webkit-box-orient: vertical;
        }
      }

      &.originally-enabled, &.tmp-enabled {
        border: 2px solid $mainBlue;
        padding: 0;

        .icon {
          color: $mainBlue;
        }
      }

      &.originally-enabled {
        height: 72px;
      }


      .take-call-wrapper {
        padding-right: 16px;
        padding-left: 54px;

        .calls-count-waiting {
          display: inline-block;
          margin-bottom: 12px;
          font-size: 12px;
          font-weight: 500;
          color: $textGray;
        }

        .chip {
          width: fit-content;

          .circle {
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: $mainGreen;
            margin-right: 8px;
          }
        }
      }
    }
  }
}
