.login-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 12px;
  row-gap: 12px;
  width: 100%;

  @media(max-width: $tabletSmPoint) {
    width: auto;
    justify-content: center;
  }
}