.call-history-call-type-cell {
  display: inline-flex;
  align-items: center;
  padding: 4px 9px;
  border-radius: 15px;
  transition: .4s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;

  .entry-type-icon {
    margin-right: 8px;
    font-size: 18px;

    &.icon-call {
      display: none;
      color: $white;
    }
  }

  @media(max-width: $laptopBigPoint) {
    height: 26px;
    width: 26px;
    padding: 0;

    .entry-type-text {
      display: none;
    }
  }

  &.missed {
    color: $mainRed;
    background-color: rgba($mainRed, 0.04);

    &:active {
      background-color: rgba($mainRed, 0.08);
    }

    &:focus {
      background-color: rgba($mainRed, 0.12);
    }
  }

  &.incoming {
    color: $mainGreen;
    background-color: rgba($mainGreen, 0.04);

    &:active {
      background-color: rgba($mainGreen, 0.08);
    }

    &:focus {
      background-color: rgba($mainGreen, 0.12);
    }
  }

  &.outgoing {
    color: $mainBlue;
    background-color: rgba($mainBlue, 0.04);

    &:active {
      background-color: rgba($mainBlue, 0.08);
    }

    &:focus {
      background-color: rgba($mainBlue, 0.12);
    }
  }

  &:hover {
    background-color: $mainGreen;
    color: $white;

    .entry-type-icon {
      display: none;

      &.icon-call {
        display: inline-block;
      }
    }
  }

  &.anonymous-call {
    pointer-events: none;
    cursor: default;
  }
}
