.call-history-pagination {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
  align-items: center;

  &-page-text {
    font-size: 12px;
    line-height: 1;
    font-weight: 600;
    color: $darkGray;
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
  }
}