.active-call-keypad-tab-selector {
  display: flex;
  justify-content: space-between;
  column-gap: 8px;
  width: 100%;
  position: relative;

  .active-call-keypad-tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    column-gap: 5px;
    width: 44px;
    min-width: 44px;
    border-radius: 22px;
    background: rgba($white, 0.04);
    color: $white;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    padding: 0 12px;
    transition: .2s linear;

    &:hover {
      background: rgba($white, 0.08);
    }

    &:active {
      background: rgba($white, 0.12);
    }

    &:focus {
      background: rgba($white, 0.16);
    }

    @include is-tablet-or-sm-mobile {
      height: 40px;
      width: 40px;
      border-radius: 20px;
      min-width: 40px;
      font-size: 12px;
      padding: 11px;
    }

    .text {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      font-size: 18px;

      @include is-tablet-or-sm-mobile {
        font-size: 16px;
      }
    }

    &-current {
      width: 100%;
      background-color: $white;
      color: $black;
      cursor: default;

      &:hover {
        background: rgba($white, 0.92);
      }

      &:active {
        background: rgba($white, 0.86);
      }

      &:focus {
        background: rgba($white, 0.78);
      }

      @include is-tablet-or-sm-mobile {
        font-size: 13px;
      }
    }

    &-tooltip {
      display: flex;
      align-items: center;

      .icon-help {
        color: $white;
        font-size: 18px;
      }
    }
  }
}