.base-checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 6px;
    border: 2px solid #DDDDDD;
    transition: .4s;
    color: $white;
    font-size: 12px;

    &:hover {
      border-color: $mainHover;
    }

    input[type="checkbox"] {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }

    .icon {
      margin-bottom: 1px;
    }
  }

  &-label {
    margin-left: 8px;
    font-weight: 500;
    font-size: 12px;
  }

  &.base-checkbox-semi-selected {
    .base-checkbox-custom {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        height: 2px;
        width: 8px;
        background-color: $white;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.base-checkbox-selected.base-checkbox-color-blue {
    .base-checkbox-custom {
      background-color: $mainBlue;
      border-color: $mainBlue;
    }
  }

  &.base-checkbox-semi-selected.base-checkbox-color-blue {
    .base-checkbox-custom {
      background-color: $mainBlue;
      border-color: $mainBlue;
    }
  }

  &.base-checkbox-selected.base-checkbox-color-green {
    .base-checkbox-custom {
      background-color: $mainGreen;
      border-color: $mainGreen;
    }
  }

  &.base-checkbox-semi-selected.base-checkbox-color-green {
    .base-checkbox-custom {
      background-color: $mainGreen;
      border-color: $mainGreen;
    }
  }

  &.base-checkbox-disabled {
    opacity: 50%;
    cursor: default;
  }
}