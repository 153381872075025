.base-country-select {
  &-activator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 12px;
    top: 6px;
    width: 40px;
    height: 28px;
    cursor: pointer;
  }

  &-search-input-group {
    position: relative;
    margin-bottom: 6px;

    input {
      height: 40px;
    }

    .icon {
      position: absolute;
      width: initial;
      height: initial;
      top: 50%;
      right: 8px;
      font-size: 16px;
      transform: translateY(-50%);
    }
  }
}