.dialpad {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;

  .number-input-container {
    position: relative;
    margin-bottom: 12px;

    .number-input {
      width: 100%;
      height: 100%;
      border-radius: 28px;
      outline: none;
      padding: 0 55px;
      border: 1px solid;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: center;

      &:focus::placeholder {
        color: transparent !important;
      }

      &::placeholder {
        font-weight: 500;
      }
    }
  }

  .keyboard-container {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    justify-items: center;
    align-items: center;
  }

  &.dial-pad-color-light {
    .number-input-container {
      .number-input {
        color: $darkGray;
        background-color: $white;
        border-color: $borderGray;

        &::placeholder {
          color: $textGray;
        }

        &:hover {
          border-color: $mainHover;
        }

        &:active {
          border-color: $mainHover;
          box-shadow: 0px 0px 0px 3px rgba(60, 91, 245, 0.24);
        }

        &:focus {
          border-color: $mainBlue;
          box-shadow: 0px 0px 0px 3px rgba(60, 91, 245, 0.24);
        }
      }
    }
  }

  &.dial-pad-color-dark {
    .number-input-container {
      .number-input {
        background: rgba($white, 0.04);
        color: $white;
        border-color: transparent;

        &::placeholder {
          color: $darkModeText;
        }

        &:hover {
          background: rgba(255, 255, 255, 0.08);
        }

        &:active {
          background: rgba(255, 255, 255, 0.12);
        }

        &:focus {
          background: rgba(255, 255, 255, 0.16);
        }
      }
    }
  }
}