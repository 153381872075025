.person-did-details {
  font-weight: 500;
  color: rgba(255, 255, 255, 0.84);
  font-size: 11px;
  line-height: 1.5;

  .caller-id-name {
    color: $darkModeText;
  }

  .label {
    color: rgba(255, 255, 255, 0.64);
  }
}