.date-picker-days {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 204px;

  .week {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .day {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    font-size: 12px;
    font-weight: 600;
    color: $darkGray;
  }

  .weekdays-wrapper {
    .day {
      text-transform: uppercase;
    }
  }

  .week {
    .day {
      cursor: pointer;
      transition: none;

      &.not-same-month {
        cursor: initial;
        color: $textGray;

        &:hover {
          background-color: unset;
        }
      }

      &.active, &.current {
        position: relative;
      }

      &.active.range-is-set, &.current.range-is-set {
        &::before, &::after {
          position: absolute;
          width: 100%;
          height: 34px;
          background-color: #E0E5FD;
          z-index: -1;
        }

        &.hover {
          &::before, &::after {
            background-color: $borderGray;
          }
        }

        &.no-right-radius {
          &::before {
            content: '';
            left: 10px;
          }
        }

        &.no-left-radius {
          &::after {
            content: '';
            right: 10px;
          }
        }
      }

      &:not(.active) {
        &.selection-range-in {
          background-color: #E0E5FD;
        }

        &.hover {
          background-color: $borderGray;
        }

        &.no-bottom-radius {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.no-top-radius {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &.no-right-radius {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &.no-left-radius {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}