.voicemail-filters-popup {
  .pick-date {
    position: relative;

    .icon-today {
      position: absolute;
      top: 50%;
      right: 12px;
      transform: translateY(-50%);
      font-size: 18px;
      color: $mainGray;
      width: initial;
      height: initial;
    }
  }

  .voicemail-select {
    span {
      font-size: 12px;
      font-weight: 500;
      color: #292929;
    }
  }

  .clear-button {
    margin-right: auto;
  }
}