.settings-menu-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 0px 14px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  transition: background 0.2s linear;

  &:hover {
    background-color: $lightGray;
  }

  &-title {
    display: flex;
    align-items: center;

    .icon {
      display: inline-block;
      font-size: 18px;
      color: $mainGray;
      margin-right: 12px;
    }
  }

  &.active {
    background-color: $lightGray;

    .settings-menu-item-title {
      .icon {
        color: $black;
      }
    }
  }

  & > .icon {
    display: none;

    @media(max-width: $laptopXSmallPoint) {
      display: inline-block;
    }
  }
}