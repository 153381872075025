.base-table-row {
  border-top: 1px solid $borderGray;

  &-item {
    padding: 11px;
    font-size: 12px;
    font-weight: 500;
    color: $darkGray;
  }

  &-selected {
    background-color: rgba($mainBlue, 0.04);
  }

  &:hover {
    background-color: rgba($mainBlue, 0.04);
  }
}