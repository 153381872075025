.base-button  {
  font-weight: 600;
  font-size: 12px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  box-sizing: border-box;
  margin: 0;
  outline: none!important;
  box-shadow: none;
  transition: .4s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: initial;

  > span.icon {
    position: unset;
    width: initial;
    height: initial;
    top: 0;
    right: 0;
    color: $mainGray;

    &::before {
      margin: 0 !important;
    }
  }

  &.base-button-size-big {
    height: 40px;
    min-width: 40px;
    border-radius: 8px;
    padding: 4px 10px;

    &.base-button-only-icon {
      border-radius: 12px;
    }
  }

  &.base-button-size-default {
    min-height: 28px;
    min-width: 28px;
    border-radius: 8px;
    padding: 4px 10px;

    &.base-button-only-icon {
      padding: 0;
    }
  }

  &.base-button-size-medium {
    height: 26px;
    min-width: 26px;
    border-radius: 10px;
    padding: 3px 4px;

    &.base-button-text-and-icon {
      padding: 3px 10px;
    }
  }

  &.base-button-size-small {
    height: 18px;
    min-width: 18px;
    border-radius: 6px;
    padding: 0;
  }

  &.base-button-color-default {
    color: $darkGray;
    background-color: $panelColor;
    border-color: $borderGray;

    > span.icon {
      color: $darkGray;
    }

    &:hover {
      border-color: $mainHover;
    }

    &:active {
      border-color: $mainHover;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:focus {
      border-color: $mainBlue;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:disabled {
      color: $mainGray;
      cursor: default;

      > span.icon {
        color: $mainGray;
      }
    }
  }

  &.base-button-color-blue {
    color: $panelColor;
    background-color: $mainBlue;
    border: none;

    &:hover {
      background-color: rgba($mainBlue, 0.84);
    }

    &:active {
      background-color: rgba($mainBlue, 0.84);
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:disabled {
      background-color: rgba($mainBlue, 0.36);
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-gray {
    border: none;
    color: $mainGray;
    position: relative;
    box-shadow: none;
    background-color: $white;

    &:hover {
      background-color: $lightGray;
    }

    &:active {
      background-color: $borderGray;
    }

    &:focus {
      background-color: $borderGray
    }

    &:disabled {
      background-color: $panelColor;
      color: $borderGray;
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-white {
    border: none;
    color: $mainBlue;
    position: relative;
    box-shadow: none;
    background-color: $white;

    &:hover {
      background-color: $white;
    }

    &:active {
      background-color: $white;
    }

    &:focus {
      background-color: $white
    }

    &:disabled {
      background-color: $white;
      color: $mainBlue;
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-table {
    color: $black;
    background-color: $lightGray;
    border: none;
    box-shadow: none;

    &:hover {
      color: $white;
      background-color: $black;
    }

    &:active {
      color: $white;
      background-color: $black;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-dark-gray {
    color: $lightGray;
    background-color: $textGray;
    border: none;
    box-shadow: none;

    &:hover {
      color: $darkGray;
      background-color: $lightGray;
    }

    &:active {
      color: $darkGray;
      background-color: $borderGray;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-orange {
    color: $white;
    background-color: $mainOrange;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($mainOrange, 0.84);
    }

    &:active {
      background-color: rgba($mainOrange, 0.84);
      box-shadow: 0 0 0 3px rgba($mainOrange, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($mainOrange, 0.24);
    }

    &:disabled {
      background-color: rgba($mainOrange, 0.36);
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-green {
    color: $white;
    background-color: $mainGreen;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($mainGreen, 0.84);
    }

    &:active {
      background-color: rgba($mainGreen, 0.84);
      box-shadow: 0 0 0 3px rgba($mainGreen, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($mainGreen, 0.24);
    }

    &:disabled {
      background-color: rgba($mainGreen, 0.36);
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-red {
    color: $white;
    background-color: $mainRed;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($mainRed, 0.84);
    }

    &:active {
      background-color: rgba($mainRed, 0.84);
      box-shadow: 0 0 0 3px rgba($mainRed, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($mainRed, 0.24);
    }

    &:disabled {
      background-color: rgba($mainRed, 0.36);
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-purple {
    color: $white;
    background-color: $mainPurple;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($mainPurple, 0.84);
    }

    &:active {
      background-color: rgba($mainPurple, 0.84);
      box-shadow: 0 0 0 3px rgba($mainPurple, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($mainPurple, 0.24);
    }

    &:disabled {
      background-color: rgba($mainPurple, 0.36);
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-black {
    color: $white;
    background-color: $black;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($black, 0.84);
    }

    &:active {
      background-color: rgba($black, 0.84);
      box-shadow: 0 0 0 3px rgba($black, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($black, 0.24);
    }

    &:disabled {
      background-color: rgba($black, 0.36);
      cursor: default;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-delete {
    color: $white;
    background-color: $mainRed;
    border-color: transparent;
    box-shadow: none;

    &:hover {
      background-color: rgba($mainRed, 0.84);
    }

    &:active {
      background-color: rgba($mainRed, 0.84);
      box-shadow: 0 0 0 3px rgba($mainRed, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($mainRed, 0.24);
    }

    &:disabled {
      color: $mainGray;
      background-color: $panelColor;
      border-color: $borderGray;
    }

    .icon {
      color: inherit;
    }
  }

  &.base-button-color-zoho-color {
    color: $white;
    background-color: $zohoColor;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($zohoColor, 0.84);
    }

    &:active {
      background-color: rgba($zohoColor, 0.84);
      box-shadow: 0 0 0 3px rgba($zohoColor, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($zohoColor, 0.24);
    }

    &:disabled {
      background-color: rgba($zohoColor, 0.36);
      cursor: default;
    }
  }

  &.base-button-color-zendesk-color {
    color: $white;
    background-color: $zendeskColor;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($zendeskColor, 0.84);
    }

    &:active {
      background-color: rgba($zendeskColor, 0.84);
      box-shadow: 0 0 0 3px rgba($zendeskColor, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($zendeskColor, 0.24);
    }

    &:disabled {
      background-color: rgba($zendeskColor, 0.36);
      cursor: default;
    }
  }

  &.base-button-color-hubspot-color {
    color: $white;
    background-color: $hubspotColor;
    border: none;
    box-shadow: none;

    &:hover {
      background-color: rgba($hubspotColor, 0.84);
    }

    &:active {
      background-color: rgba($hubspotColor, 0.84);
      box-shadow: 0 0 0 3px rgba($hubspotColor, 0.24);
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($hubspotColor, 0.24);
    }

    &:disabled {
      background-color: rgba($hubspotColor, 0.36);
      cursor: default;
    }
  }

  &.base-button-color-text {
    color: $darkGray;
    background-color: transparent;
    border: none;
    box-shadow: none;

    &:hover {
      color: $textGray;
    }

    &:active {
      color: $textGray;
    }

    &:focus {
      color: $black;
    }
  }

  &.base-button-loading {
    position: relative;
    background-color: rgba(60, 91, 245, 0.16) !important;

    .text {
      visibility: hidden;
    }

    .base-spinner {
      position: absolute;
    }
  }

  &.base-button-circle {
    border-radius: 50%;
  }

  &.base-button-text-and-icon {
    .icon {
      margin-right: 8px;
    }
  }

  &.base-button-disable-borders {
    border: none;
    box-shadow: none;

    &:active {
      box-shadow: none;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.base-button-text-over-icon {
    position: relative;

    .text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 8px;
      line-height: 8px;

      &::before {
        content: '';
        width: 100%;
        height: 9px;
        position: absolute;
        left: 0;
        top: -1px;
        z-index: -1;
        background-color: #fff;
      }
    }
  }

  &.base-button-text-button.base-button-color-white {
    color: $mainBlue;

    &:hover {
      color: rgba($mainBlue, 0.84);
    }
  }
}