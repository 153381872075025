.softphone-button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  opacity: 1;
  transition: opacity 0.2s linear;

  &-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.2s linear;

    .icon {
      transition: background 0.2s linear;
    }
  }

  &-label {
    display: inline-block;
    font-weight: 500;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    text-align: center;
    color: $darkModeText;
    cursor: default;
    width: calc(100% + 22px);
  }

  &-disabled {
    opacity: 0.34 !important;

    .softphone-button-button {
      cursor: default;
    }
  }

  &-size-default {
    width: 60px;

    .softphone-button-button {
      width: 60px;
      height: 60px;

      .icon {
        font-size: 32px;
      }
    }

    .softphone-button-label {
      margin-top: 8px;
      font-size: 12px;
      line-height: 18px;
    }
  }

  &-size-small {
    width: 48px;

    .softphone-button-button {
      width: 48px;
      height: 48px;

      .icon {
        font-size: 24px;
      }
    }

    .softphone-button-label {
      margin-top: 4px;
      font-size: 11px;
      line-height: 16px;
    }
  }
}