.base-sortable-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  .selected {
    opacity: 0;
  }

  &-item {
    cursor: move;

    &:last-child {
      margin-bottom: 0;
    }
  }
}