.app-unavailable-title {
  text-align: center;
  font-size: 32px;
  line-height: 32px;

  @media(max-width: $laptopPoint) {
    font-size: 24px;
    line-height: 24px;
  }

  @media(max-width: $tabletPoint) {
    font-size: 20px;
    line-height: 25px;
  }

  @media(max-width: $phonePoint) {
    font-size: 18px;
    line-height: 24px;
  }
}