.panel {
  width: 100%;
  border-radius: 12px;
  height: 100%;
  position: relative;
  overflow: hidden;

  background-color: $panelColor;

  @media(max-width: $tabletLargePoint) {
    border-radius: 0;
  }

  max-width: calc(100% - 506px - 35px);

  @media(max-width: $laptopPoint) {
    max-width: calc(100% - 406px - 20px);
  }

  @media(max-width: $tabletPoint) {
    max-width: calc(100% - 336px - 10px);
  }

  @media(max-width: $tabletLargePoint) {
    max-width: 100%;
  }

  .tab {
    display: none;
    width: 100%;
    height: 100%;
    flex-direction: column;
    border-radius: inherit;
    padding-top: 16px;

    &.active {
      display: flex;
    }
  }

  .softphone-tab-pane {
    height: 100%;
    display: none;

    &.active {
      @media(max-width: $laptopSmallPoint) {
        display: block;
      }
    }

    & .softphone {
      margin: 0 auto;
      @media(max-width: $laptopSmallPoint) {
        display: flex;
      }
    }
  }

  .integrations-popup {
    position: absolute;
    top: 0;
    left: 0;
  }
}