.call-settings-button {
  .softphone-button {
    &-button {
      background: rgba($white, 0.04);

      &:hover {
        background: rgba($white, 0.08);
      }

      &:active {
        background: rgba($white, 0.12);
      }
    }
  }

  &.softphone-button-disabled .softphone-button-button {
    background: rgba($white, 0.04);
  }

  .icon {
    color: $white;
  }

  &-active {
    .softphone-button {
      &-button {
        background: $white;

        &:hover {
          background: rgba($white, 0.92);
        }

        &:active {
          background: rgba($white, 0.86);
        }
      }
    }

    .icon {
      color: $darkModeBlack;
    }
  }

  &-active.softphone-button-disabled .softphone-button-button {
    background: $white;
  }
}