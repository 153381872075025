.history-tab {
  height: 100%;
  overflow: hidden;

  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    row-gap: 6px;
    column-gap: 12px;
    padding: 0 16px;
    margin-bottom: 8px;

    .chip .chip-button-close {
      margin-left: 8px;
    }
  }
}