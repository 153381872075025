.voicemail-item-table-expanded-row {
  padding-top: 8px;

  //@media(max-width: $tabletSmPoint) {
  //  background-color: rgba($mainBlue, 0.04);
  //}

  .mobile-expanded {
    padding: 0 16px 8px 11px;

    &-controls {
      display: flex;
      justify-content: flex-start;

      > .base-button {
        margin-right: 12px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &-audio-player {
      display: flex;
      justify-content: flex-start;

      .audio-player {
        margin-right: 12px;
      }
    }
  }

  .integrations-inline {
    .create-contact, .crm-contact-info-wrapper, .crm-info-no-enabled-crms {
      margin-left: 11px !important;
    }
  }
}