.call-history-table-row {
  border-top: 1px solid rgba($mainBlue, 0.07);

  &-cell {
    padding: 8px 11px;
    font-size: 12px;
    font-weight: 500;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 12px;
  }

  &:hover {
    background-color: rgba($mainBlue, 0.04);

    + .base-table-expanded-row {
      background-color: rgba($mainBlue, 0.04);
    }
  }
}