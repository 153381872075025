/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?63587838#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-arrow-left:before { content: '\e800'; } /* '' */
.icon-arrow-down:before { content: '\e801'; } /* '' */
.icon-arrow-right:before { content: '\e802'; } /* '' */
.icon-arrow-up:before { content: '\e803'; } /* '' */
.icon-stories:before { content: '\e804'; } /* '' */
.icon-call-end:before { content: '\e805'; } /* '' */
.icon-backspace:before { content: '\e806'; } /* '' */
.icon-call:before { content: '\e807'; } /* '' */
.icon-call-ingoing:before { content: '\e808'; } /* '' */
.icon-call-missed:before { content: '\e809'; } /* '' */
.icon-call-outgoing:before { content: '\e80a'; } /* '' */
.icon-split:before { content: '\e80b'; } /* '' */
.icon-chevron-left:before { content: '\e80c'; } /* '' */
.icon-check-circle:before { content: '\e80d'; } /* '' */
.icon-chevron-right:before { content: '\e80e'; } /* '' */
.icon-clear:before { content: '\e80f'; } /* '' */
.icon-cloud:before { content: '\e810'; } /* '' */
.icon-dialpad:before { content: '\e811'; } /* '' */
.icon-dark-mode:before { content: '\e812'; } /* '' */
.icon-sms:before { content: '\e813'; } /* '' */
.icon-edit:before { content: '\e814'; } /* '' */
.icon-error:before { content: '\e815'; } /* '' */
.icon-seat:before { content: '\e816'; } /* '' */
.icon-expand-up:before { content: '\e817'; } /* '' */
.icon-expand-down:before { content: '\e818'; } /* '' */
.icon-history:before { content: '\e819'; } /* '' */
.icon-light-mode:before { content: '\e81a'; } /* '' */
.icon-lens:before { content: '\e81b'; } /* '' */
.icon-logout:before { content: '\e81c'; } /* '' */
.icon-lock:before { content: '\e81d'; } /* '' */
.icon-menu:before { content: '\e81e'; } /* '' */
.icon-mic:before { content: '\e81f'; } /* '' */
.icon-mic-off:before { content: '\e820'; } /* '' */
.icon-more:before { content: '\e821'; } /* '' */
.icon-password:before { content: '\e822'; } /* '' */
.icon-pause:before { content: '\e823'; } /* '' */
.icon-person-add:before { content: '\e824'; } /* '' */
.icon-person-add2:before { content: '\e825'; } /* '' */
.icon-person:before { content: '\e826'; } /* '' */
.icon-person-remove:before { content: '\e827'; } /* '' */
.icon-person-search:before { content: '\e828'; } /* '' */
.icon-call-forwarded:before { content: '\e829'; } /* '' */
.icon-call-paused:before { content: '\e82a'; } /* '' */
.icon-play:before { content: '\e82b'; } /* '' */
.icon-radio-checked:before { content: '\e82c'; } /* '' */
.icon-radio-unchecked:before { content: '\e82d'; } /* '' */
.icon-report:before { content: '\e82e'; } /* '' */
.icon-search:before { content: '\e82f'; } /* '' */
.icon-settings:before { content: '\e830'; } /* '' */
.icon-supervisor:before { content: '\e831'; } /* '' */
.icon-swap:before { content: '\e832'; } /* '' */
.icon-warning:before { content: '\e833'; } /* '' */
.icon-file_download:before { content: '\e834'; } /* '' */
.icon-volume_off:before { content: '\e835'; } /* '' */
.icon-today:before { content: '\e836'; } /* '' */
.icon-join-right-black:before { content: '\e837'; } /* '' */
.icon-join-full-black:before { content: '\e838'; } /* '' */
.icon-refresh:before { content: '\e839'; } /* '' */
.icon-reply-all:before { content: '\e83a'; } /* '' */
.icon-windows:before { content: '\e83b'; } /* '' */
.icon-linux:before { content: '\e83c'; } /* '' */
.icon-macos:before { content: '\e83d'; } /* '' */
.icon-call-merge:before { content: '\e83e'; } /* '' */
.icon-filter:before { content: '\e83f'; } /* '' */
.icon-done-all:before { content: '\e840'; } /* '' */
.icon-new-releases:before { content: '\e841'; } /* '' */
.icon-done:before { content: '\e842'; } /* '' */
.icon-local-parking:before { content: '\e843'; } /* '' */
.icon-lock-open:before { content: '\e844'; } /* '' */
.icon-dots:before { content: '\e856'; } /* '' */
.icon-phone-parked:before { content: '\e857'; } /* '' */
.icon-copy:before { content: '\e858'; } /* '' */
.icon-headphones:before { content: '\e867'; } /* '' */
.icon-rectangle:before { content: '\e884'; } /* '' */
.icon-volume-up:before { content: '\e886'; } /* '' */
.icon-devices:before { content: '\e888'; } /* '' */
.icon-arrow-drop-down:before { content: '\e88a'; } /* '' */
.icon-arrow-drop-up:before { content: '\e88b'; } /* '' */
.icon-delete-forever:before { content: '\e88c'; } /* '' */
.icon-delete-forever-1:before { content: '\e88e'; } /* '' */
.icon-teams:before { content: '\e88f'; } /* '' */
.icon-phone:before { content: '\e892'; } /* '' */
.icon-send:before { content: '\e893'; } /* '' */
.icon-sim-card:before { content: '\e894'; } /* '' */
.icon-plus-in-circle:before { content: '\e896'; } /* '' */
.icon-visibility:before { content: '\e89e'; } /* '' */
.icon-visibility-off:before { content: '\e89f'; } /* '' */
.icon-open-in-new:before { content: '\e8a3'; } /* '' */
.icon-webphone:before { content: '\e8ac'; } /* '' */
.icon-add:before { content: '\e8ad'; } /* '' */
.icon-android:before { content: '\e8ae'; } /* '' */
.icon-cancel:before { content: '\e8b8'; } /* '' */
.icon-help:before { content: '\e8ba'; } /* '' */
.icon-mark-chat-unread:before { content: '\e8bb'; } /* '' */
.icon-fact-check:before { content: '\e8be'; } /* '' */
.icon-voicemail:before { content: '\e8c2'; } /* '' */
.icon-integration:before { content: '\e906'; } /* '' */
.icon-integration-zendesk:before { content: '\e907'; } /* '' */
