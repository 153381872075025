.softphone-do-call-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  background-color: $mainGreen;
  color: $white;
  border-radius: 50%;
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.2s linear;

  @include is-tablet-or-sm-mobile {
    width: 48px;
    height: 48px;
  }

  &:hover:not(&-disabled) {
    opacity: 0.92;
  }

  &-disabled {
    opacity: 0.34;
    cursor: default;
  }

  .icon {
    font-size: 32px;

    @include is-tablet-or-sm-mobile {
      font-size: 24px;
    }
  }
}