.auth-layout {
  height: 100vh;
  width: 100%;
  padding: 16px;
  background-color: $panelColor;
  overflow-y: auto;

  &-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 873px;
    margin: 0 auto;

    @media(max-width: $laptopPoint) {
      max-width: 833px;
    }

    @media(max-width: $tabletPoint) {
      max-width: 793px;
    }
  }

  &-top {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin: auto 0;
    justify-content: space-between;
    align-items: center;

    @media(max-width: $tabletSmPoint) {
      justify-content: center;
      flex-direction: column;
      row-gap: 24px;
    }
  }

  &-top-left {
    display: flex;
    flex-direction: column;

    @media(max-width: $tabletSmPoint) {
      max-width: 375px;
      width: 100%;
    }

    .auth-layout-logo {
      width: fit-content;
      text-align: center;

      img {
        max-width: 300px;
        height: 52px;
        object-fit: contain;

        @media(max-width: $tabletSmPoint) {
          width: 68px;
          height: 34px;
        }
      }
    }

    h1 {
      font-size: 44px;
      margin-bottom: 5px;
      font-weight: 640;
      line-height: 100%;
      letter-spacing: -1.056px;
      margin-top: 20px;
      color: #27549F;

      @media(max-width: $tabletSmPoint) {
        font-size: 16px;
        font-weight: 600;
        color: $darkGray;
      }
    }

    .auth-layout-captcha {
      margin-top: 32px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      max-width: 338px;
      width: 100%;
      color: $darkGray;

      a {
        color: $darkGray;
        text-decoration: underline;
        transition: color 0.2s linear;

        &:hover {
          color: $mainBlue;
        }
      }
    }
  }

  &-top-right {
    display: flex;
    flex-direction: column;
    row-gap: 18px;
    max-width: 375px;
    width: 100%;
    position: relative;

    .buttons-group {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      column-gap: 24px;
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    column-gap: 12px;

    @media(max-width: $tabletSmPoint) {
      justify-content: center;
    }
  }
}