.service-tab {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  padding-bottom: 16px;

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
    margin-left: 16px;
    row-gap: 8px;
  }

  &-body {
    height: 100%;
  }
}