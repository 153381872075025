.call-recordings-expanded-tab {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  align-items: center;
  margin-top: 12px;
  margin-left: 16px;
  margin-right: 34px;
  padding-bottom: 12px;

  @media(max-width: $phonePoint) {
    margin-top: 10px;
  }

  .audio-player {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .base-spinner {
    margin: 0 auto;
  }
}