.date-picker-months {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 204px;

  button {
    width: 30%
  }
}