.dial-pad-action-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    outline: none;
    border: none;
    transition: background 0.1s linear;

    .icon {
      position: relative;
      font-size: 20px;
      transition: color 0.1s linear;
    }
  }

  &-position-right {
    right: 3px;
  }

  &-position-left {
    left: 3px;
  }

  &-color-light {
    button {
      background: $white;

      &:hover {
        background: rgba(0, 0, 0, 0.04);

        .icon {
          color: $textGray;
        }
      }

      &:active {
        background: rgba(0, 0, 0, 0.08);

        .icon {
          color: $darkGray;
        }
      }

      &:focus {
        background: rgba(0, 0, 0, 0.12);

        .icon {
          color: $darkGray;
        }
      }

      .icon {
        color: $mainGray;
      }
    }
  }

  &-color-dark {
    button {
      background: transparent;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
      }

      &:active {
        background: rgba(255, 255, 255, 0.12);
      }

      &:focus {
        background: rgba(255, 255, 255, 0.16);
      }

      .icon {
        color: $white;
      }
    }
  }
}