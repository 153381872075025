.audio-player {
  display: flex;
  align-items: center;
  width: 100%;

  .controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 12px;

    .play-button {
      margin-right: 10px;
    }

    .time {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 12px;
      color: $darkGray;
      font-weight: 500;
      width: 80px;
      position: relative;

      .delimiter {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  .timeline-container {
    position: relative;
    //max-width: 512px;
    width: 100%;

    .timeline {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 6px;
      background-color: #DDDDDD;
      border-radius: 3px;
      cursor: pointer;

      .track {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }

      .buffer-track {
        z-index: 10;
        background-color: #858585;
      }

      .progress-track {
        z-index: 20;
        background-color: #292929;
      }
    }

    .seek-time {
      position: absolute;
      top: calc(-200% - 5px);
      transform: translateX(-50%);
      font-size: 10px;
      font-weight: 500;
      color: $mainGray;
    }
  }

  .download-button {
    margin-left: 10px;
  }
}