.base-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    table-layout: fixed;
  }

  &-rounded {
    border: 1px solid $borderGray;
    border-radius: 8px;
  }

  &-content {
    height: 100%;
    min-height: 0;
  }

  &-empty {
    font-size: 16px;
    font-weight: 600;
    color: $darkGray;
    width: fit-content;
  }

  // override vue-bar styles
  &-content-scroll {
    height: 100%;
    width: 100% !important;
    padding-right: 20px !important;
    box-sizing: content-box !important;
    scrollbar-width: none;
  }

  &-content-scroll::-webkit-scrollbar {
    display: none;
  }
}