.dial-pad-keyboard-button {
  cursor: pointer;
  font-weight: 600;
  line-height: 1.2;

  &-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    border-radius: 50%;
  }

  &-pound-sign {
    font-weight: 700;
  }

  &-color-light {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.025), rgba(0, 0, 0, 0.025)), #FFFFFF;

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), #FFFFFF;
    }

    &:active {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF;
    }

    &:focus {
      outline: none;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), #FFFFFF;
    }

    .dial-pad-keyboard-button-sign {
      color: $darkGray;
    }

    .dial-pad-keyboard-button-text {
      color: $textGray;
    }
  }

  &-color-dark {
    &:hover {
      background: rgba(255, 255, 255, 0.08);
    }

    &:active {
      background: rgba(255, 255, 255, 0.12);
    }

    &:focus {
      background: rgba(255, 255, 255, 0.16);
    }

    .dial-pad-keyboard-button-sign {
      color: $white;
    }

    .dial-pad-keyboard-button-text {
      color: $darkModeText;
    }
  }
}