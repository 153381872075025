.base-slider {
  width: 100%;
  height: 12px;
  position: relative;

  &-tooltip {
    position: absolute;
    top: -35px;
    padding: 6px 12px;
    color: $white;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    height: fit-content;
    border-radius: 8px;
    background-color: $black;
    transition: opacity .2s ease-in-out;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      background-color: $black;
      left: 50%;
      bottom: -4px;
      transform: translateX(-50%) rotate(45deg);
    }
  }

  input {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 100%;
    height: 12px;
    position: relative;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      position: relative;
      border: none;
      margin-top: -3px;
      background-color: $mainBlue;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }

    &::-webkit-slider-runnable-track {
      height: 6px;
      border: none;
      border-radius: 3px;
      background: $borderGray;
      box-shadow: none;
    }

    &::-webkit-slider-runnable-track {
      background: linear-gradient(#A2B1FB,#A2B1FB) 0/calc(var(--ratio) * 100%) 100% no-repeat, #E6E6E6;
    }

    /*mozilla*/
    &::-moz-range-thumb {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $mainBlue;
      border: none;
      box-shadow: none;
    }

    &::-moz-range-track {
      height: 6px;
      border: none;
      border-radius: 3px;
      background: $borderGray;
      box-shadow: none;
    }

    &::-moz-range-track {
      background: linear-gradient(#A2B1FB,#A2B1FB) 0/var(--sx) 100% no-repeat, $borderGray;
    }
  }
}