.phonebook-select-list-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 32px;
  border-radius: 8px;
  padding: 0 12px;
  background: $darkModeBlack;
  transition: background 0.2s linear;
  cursor: pointer;
  margin-bottom: 2px;

  &:hover {
    background: rgba($white, 0.08);
  }

  &:active {
    background: rgba($white, 0.12);
  }

  &:focus {
    background: rgba($white, 0.16);
  }

  &-selected {
    background: rgba($white, 0.08);
  }

  &-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 600;
    color: $white;
  }
}