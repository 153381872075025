.contact-speed-dial-list-item {
  height: 44px;
  padding: 4px 6px;

  @include is-tablet-or-sm-mobile {
    height: 40px;
    padding: 2px 6px;
  }

  .contact-name-number-group {
    justify-content: center;
  }

  .name {
    font-size: 13px;
    line-height: 20px;
  }

  .number {
    font-size: 11px;
    line-height: 16px;
  }
}