.call-status-label {
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: $darkModeText;
  white-space: nowrap;

  @include is-tablet-or-sm-mobile {
    font-size: 13px;
  }

  &-animated {
    background: radial-gradient(6.58% 70% at 50% 50%, rgba(255, 255, 255, 0.84) 0%, rgba(255, 255, 255, 0.2016) 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2.5s infinite linear;
  }

  @keyframes textclip {
    from {
      background-position: right top;
      background-size: 300% 100%;
    }
    to {
      background-position: left top;
      background-size: 300% 100%;
    }
  }
}