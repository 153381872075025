.integrations-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
  height: 40px;
  padding: 0 10px;
  border-radius: 24px;
  border: 2px solid #9dadfa;
  color: $white;
  outline: none;
  background: transparent;
  transition: background 0.2s linear;
  cursor: pointer;

  @include is-tablet-or-sm-mobile {
    min-width: 192px;
    height: 36px;
  }

  &:hover {
    background: linear-gradient(0deg, rgba(60, 91, 245, 0.04), rgba(60, 91, 245, 0.04)), rgba(255, 255, 255, 0.04);;
  }

  &:active {
    background: linear-gradient(0deg, rgba(60, 91, 245, 0.08), rgba(60, 91, 245, 0.08)), rgba(255, 255, 255, 0.08);;
  }

  &:focus {
    background: linear-gradient(0deg, rgba(60, 91, 245, 0.12), rgba(60, 91, 245, 0.12)), rgba(255, 255, 255, 0.12);;
  }

  &-active {
    .icon-chevron-right {
      transform: rotate(180deg);
    }
  }

  .icon {
    transition: transform .2s linear;
    font-size: 24px;

    @include is-tablet-or-sm-mobile {
      font-size: 22px;
    }
  }

  &-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    @include is-tablet-or-sm-mobile {
      font-size: 13px;
    }
  }
}