$desktopSmallPoint: 1440px;
$laptopMediumPoint: 1300px;
$laptopBigPoint: 1100px;
$laptopSmallPoint: 1040px;
$laptopXSmallPoint: 960px;
$tabletLargePoint: 800px;
$tabletBigPoint: 720px;
$tabletSmallPoint: 620px;
$phoneBigPoint: 420px;
$phoneMediumPoint: 400px;
$phoneSmallPoint: 390px;

$laptopPoint: 1536px;
$tabletPoint: 1100px;
$tabletSmPoint: 800px;
$phonePoint: 480px;

$imgPath: "../images";
$fontPath : "../../font";

$mainBackgroundColor: #F7F9FF;
$panelColor: #FFFFFF;
$black: #000000;
$blackTransparent: #000000cc;
$mainGray: #858585;
$borderGray: #e5e5e5;
$mainBlue: #3c5bf5;
$mainRed: #F24343;
$mainGreen: #38B955;
$darkGreen: #03363D;
$mainOrange: #FFA600;
$additionalOrange: #FF7A59;
$mainHover: #a1b0fa;
$darkGray: #292929;
$textGray: #666666;
$lightGray: #F7F7F7;
$background: linear-gradient(0deg, rgba(60, 91, 245, 0.04), rgba(60, 91, 245, 0.04)), #FFFFFF;
$white: #ffffff;
$darkModeText: rgba(255, 255, 255, 0.84);
$darkModeGrey: rgba(255, 255, 255, 0.72);
$darkModeBorder: linear-gradient(0deg, rgba(60, 91, 245, 0.16), rgba(60, 91, 245, 0.16)), linear-gradient(0deg, rgba(0, 0, 0, 0.72), rgba(0, 0, 0, 0.72)), #FFFFFF;
$darkModeLightGrey: linear-gradient(0deg, rgba(60, 91, 245, 0.16), rgba(60, 91, 245, 0.16)), linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), #FFFFFF;
$darkModeBlack: #2C314A;
$darkModeBackground: linear-gradient(0deg, rgba(60, 91, 245, 0.16), rgba(60, 91, 245, 0.16)), linear-gradient(0deg, rgba(0, 0, 0, 0.88), rgba(0, 0, 0, 0.88)), #FFFFFF;
$zohoColor: #d82231;
$hubspotColor: #ff7a59;
$zendeskColor: #04363d;
$mainPurple: #975FF2;