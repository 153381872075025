.history-speed-dial-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 4px 6px;

  @include is-tablet-or-sm-mobile {
    height: 40px;
    padding: 2px 6px;
  }

  &-date {
    font-size: 11px;
    font-weight: 500;
    color: rgba($white, 0.68);
    margin-right: 16px;
    white-space: nowrap;
  }

  .contact {
    width: 100%;
    min-width: 0;
  }

  .name {
    font-size: 13px;
    line-height: 20px;
  }

  .number {
    font-size: 11px;
    line-height: 16px;
  }
}