.settings-tab-general {
  .base-switch {
    margin-bottom: 16px;
  }

  .notification-section {
    min-height: 40px;
    width: 100%;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid $borderGray;
    padding: 11px 13px;

    label {
      margin-bottom: 0;
    }

    .tip {
      display: none;
      margin-left: 14px;
    }

    .blocked {
      margin-top: 10px;
      font-weight: 500;
      font-size: 12px;
      color: $darkGray;
      line-height: 14px;
      padding-left: 30px;
    }

  }
}