.phonebook-select {
  position: relative;
  max-width: 100%;

  &-activator {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 14px;
    min-width: 214px;
    transition: background 0.2s linear;
    cursor: pointer;
    max-width: 100%;

    &:hover {
      background: rgba($white, 0.08);
    }

    &:active {
      background: rgba($white, 0.12);
    }

    &:focus {
      background: rgba($white, 0.16);
    }

    .text {
      display: inline-block;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      padding: 3px 12px;
    }

    .icon {
      font-size: 20px;
    }
  }

  &-dropdown {
    border-radius: 12px;
    background-color: $darkModeBlack;
    border: 1px solid rgba($white, 0.08);
  }
}