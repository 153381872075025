.chip {
  display: flex;
  align-items: center;
  border-radius: 8px;
  cursor: default;
  transition: .4s;
  white-space: nowrap;
  width: fit-content;

  .text {
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
  }

  &.chip-size-big {
    height: 40px;
    padding: 0 14px;
  }

  &.chip-size-default {
    height: 28px;
    padding: 0 10px;
  }

  &.chip-size-small {
    height: 20px;
    padding: 0 8px;
    border-radius: 5px;
  }

  &.chip-color-light-blue {
    background-color: #CBDAFF;

    &:hover {
      background-color: #B8CCFF;
    }

    &:active {
      background-color: #ADC4FF;
    }

    &:focus {
      background-color: #A8C0FF;
    }

    .text {
      color: $mainBlue;
    }
  }

  &.chip-color-light-green {
    background-color: #effaf2;

    .text {
      color: $mainGreen;
    }
  }

  &.chip-color-light-orange {
    background-color: #fff7e8;

    .text {
      color: $mainOrange;
    }
  }

  &.chip-color-light-red {
    background-color: #fef0f0;

    .text {
      color: $mainRed;
    }
  }

  &.chip-light-gray {
    background-color: rgba($mainGray, 0.1);

    .text {
      color: $mainGray;
    }
  }

  &.chip-color-white {
    background-color: $white;
    border: 1px solid $borderGray;

    .text {
      color: $darkGray;
    }
  }

  &.chip-color-green {
    background-color: $mainGreen;

    .text {
      color: $white;
    }
  }

  &.chip-color-gray {
    background-color: $mainGray;

    .text {
      color: $white;
    }
  }

  &.chip-color-border-gray {
    background-color: $lightGray;
    border: 1px solid $borderGray;

    .text {
      color: $darkGray;
    }
  }

  &.chip-disable-borders {
    border: none;
  }

  &.chip-align-left {
    justify-content: flex-start;
  }

  &.chip-align-center {
    justify-content: center;
  }

  &.chip-align-right {
    justify-content: flex-end;
  }
}