.call-controls-button {
  &-icon {
    color: $white;
  }

  &-color-green .softphone-button-button {
    background-color: $mainGreen;

    &:hover {
      background: rgba($mainGreen, 0.92);
    }

    &:active {
      background: rgba($mainGreen, 0.86);
    }

    &:focus {
      background: rgba($mainGreen, 0.78);
    }
  }

  &-color-green.softphone-button-disabled .softphone-button-button {
    background-color: $mainGreen;
  }

  &-color-red .softphone-button-button {
    background-color: $mainRed;

    &:hover {
      background: rgba($mainRed, 0.92);
    }

    &:active {
      background: rgba($mainRed, 0.86);
    }

    &:focus {
      background: rgba($mainRed, 0.78);
    }
  }

  &-color-red.softphone-button-disabled .softphone-button-button {
    background-color: $mainRed;
  }

  &-color-blue .softphone-button-button {
    background-color: $mainBlue;

    &:hover {
      background: rgba($mainBlue, 0.92);
    }

    &:active {
      background: rgba($mainBlue, 0.86);
    }

    &:focus {
      background: rgba($mainBlue, 0.78);
    }
  }

  &-color-blue.softphone-button-disabled .softphone-button-button {
    background-color: $mainBlue;
  }

  &-color-orange .softphone-button-button {
    background-color: $mainOrange;

    &:hover {
      background: rgba($mainOrange, 0.92);
    }

    &:active {
      background: rgba($mainOrange, 0.86);
    }

    &:focus {
      background: rgba($mainOrange, 0.78);
    }
  }

  &-color-orange.softphone-button-disabled .softphone-button-button {
    background-color: $mainOrange;
  }
}