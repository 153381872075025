@import "./vars.scss";

@property --opacityFirst {
  syntax: '<percentage>';
  initial-value: 25%;
  inherits: false;
}

@property --opacitySecond {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: false;
}

@property --opacityThird {
  syntax: '<percentage>';
  initial-value: 100%;
  inherits: false;
}

@property --opacityFourth {
  syntax: '<percentage>';
  initial-value: 0%;
  inherits: false;
}

#app___loading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: $panelColor;
  margin-bottom: 100%;

  svg {
    .gradient {
      width: 482px;
      height: 300px;
      background-image: conic-gradient(from -45deg at 19.53% 37.33%, rgba(60, 91, 245, var(--opacityFirst)) 0deg, rgba(60, 91, 245, var(--opacitySecond)) 360deg),
                        conic-gradient(from 45deg at 76.83% 24.67%, rgba(60, 91, 245, var(--opacityThird)) 0deg, rgba(60, 91, 245, var(--opacityFourth)) 360deg);
      animation: gradient 2.5s infinite linear;
    }

    @media (max-width: $laptopPoint) {
      width: 385px;
      height: 240px;
    }

    @media (max-width: $tabletPoint) {
      width: 320px;
      height: 200px;
    }

    @media (max-width: $phonePoint) {
      width: 192px;
      height: 120px;
    }
  }
}

@keyframes gradient {
  25% {
    --opacityFirst: 25%;
    --opacitySecond: 0%;
    --opacityThird: 50%;
    --opacityFourth: 0%;
  }

  50% {
    --opacityFirst: 100%;
    --opacitySecond: 0%;
    --opacityThird: 25%;
    --opacityFourth: 0%;
  }

  75% {
    --opacityFirst: 50%;
    --opacitySecond: 0%;
    --opacityThird: 25%;
    --opacityFourth: 0%;
  }
}