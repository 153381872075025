.auth-errors {
  display: block;
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: $mainRed;
}