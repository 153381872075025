.two-factor-auth {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  color: $darkGray;

  h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
  }

  p {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
  }
}