.download-popup {
  z-index: 999;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 12px;
  }

  &-copy-section {
    cursor: pointer;
  }
}