.integrations-popup {
  .base-popup-content {
    display: flex;
    flex-direction: column;

    .crm-info {
      max-height: unset;
      min-height: 0;
      height: 100%;
    }
  }

  .call-contacts {
    display: flex;
    justify-content: flex-start;

    .call-contact {
      flex-basis: fit-content;
      height: 34px;
      margin-right: 16px;
      cursor: pointer;
      position: relative;

      @media(max-width: $phonePoint) {
        height: 2.7em;
        margin-right: 0.9em;
      }

      &.active {
        &::before {
          content: '';
          position: absolute;
          top: -11px;
          left: 50%;
          transform: translateX(-50%);
          width: 18px;
          height: 2px;
          background-color: $mainBlue;

          @media(max-width: $laptopPoint) {
            top: -9px;
          }

          @media(max-width: $phonePoint) {
            width: 1.5em;
            height: 0.2em;
            top: -0.75em;
          }
        }

        .avatar-container {
          opacity: 1;
        }
      }

      &.contact-speaks {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          width: 12px;
          height: 12px;
          border: 2px solid #FFFFFF;
          background-color: $mainGreen;
          z-index: 9;
          border-radius: 50%;
        }
      }

      .avatar-container {
        margin: 0;
        opacity: 0.64;

        .avatar.medium {
          width: 34px !important;
          height: 34px !important;
          font-size: 18px !important;

          @media(max-width: $phonePoint) {
            width: 1.8em !important;
            height: 1.8em !important;
            font-size: 1.5em !important;
          }
        }
      }
    }
  }

  .zapier-info {
    &-blink {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      border-radius: 12px;
      background: transparent;
      animation: blink 1s 7 alternate;

      @keyframes blink {
        from {
          background: rgba(60, 91, 245, 0.06);
        }
        to {
          background: transparent;
        }
      }
    }

    &-wrapper {
      height: 127px;
      padding-left: 50px;
      margin-top: 16px;

      @media(max-width: $phonePoint) {
        padding-left: 3.4em;
      }
    }
  }
}