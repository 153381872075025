.base-switch {
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;

  .slider {
    display: inline-block;
    position: relative;
    width: 22px;
    height: 14px;
    background-color: $borderGray;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
    min-width: 22px;

    &::before {
      position: absolute;
      content: "";
      height: 8px;
      width: 8px;
      left: 3px;
      bottom: 3px;
      background-color: $panelColor;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

  }

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:disabled + .slider {
      background-color: rgba($borderGray, 0.36);
    }

    &:checked + .slider {
      background-color: $mainBlue;
    }

    &:checked:disabled + .slider {
      background-color: rgba($mainBlue, 0.36);
    }

    &:checked + .slider::before {
      background-color: $panelColor;
    }

    &:checked + .slider::before {
      transform: translateX(8px);
    }

    &:active {
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }
  }


  .label-text {
    font-weight: 500;
    font-size: 12px;
    color: $darkGray;
    line-height: 14px;
    margin-left: 8px;

    &.prepend {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  &-border {
    height: 40px;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #e5e5e5;
    padding: 11px 13px;
  }
}