.date-picker-shortcuts {
  @media(max-width: $tabletSmPoint) {
    display: flex;
    height: 50px;
    width: 100%;
    overflow-x: auto;

    > button.base-button {
      min-width: fit-content;
    }
  }

  button {
    width: 128px;
    margin-bottom: 10px;

    @media(max-width: $tabletSmPoint) {
      margin-right: 7px;
      margin-bottom: 0;
      min-width: fit-content;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      color: $mainBlue;
    }
  }
}