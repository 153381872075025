.date-picker-years {
  height: 256px;

  .years-wrapper {
    padding-right: 20px;

    button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
}