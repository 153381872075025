.softphone-state-active-multiple {
  &-transfer-button-colored .softphone-button-button {
    background: $mainBlue;

    &:hover {
      background: rgba($mainBlue, 0.92);
    }

    &:active {
      background: rgba($mainBlue, 0.86);
    }
  }
}