.crm-contact-minimized {
  display: flex;
  width: 100%;
  cursor: pointer;

  .info-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .data {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: $darkGray;

      @media(max-width: $phonePoint) {
        font-size: 1.45em;
        line-height: 1em;
      }
    }

    .label {
      display: inline-block;
      font-size: 12px;
      line-height: 12px;
      font-weight: 500;
      margin-top: 6px;
      color: $textGray;

      @media(max-width: $phonePoint) {
        font-size: 1.1em;
        line-height: 1.1em;
        margin-top: 0;
      }
    }
  }

  img {
    width: 34px;
    height: 34px;
    margin-right: 16px;

    @media(max-width: $phonePoint) {
      width: 2.7em;
      height: 2.7em;
      margin-right: 0.7em;
    }
  }

  .icon {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    color: $mainGray;
    font-size: 18px;

    @media(max-width: $phonePoint) {
      font-size: 1.6em;
    }
  }

  &.inline {
    .info-group {
      .data {
        font-size: 12px;
        line-height: 12px;
      }

      .label {
        font-size: 9px;
        line-height: 9px;
      }
    }

    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
  }
}
