.settings-tab {
  background-color: $panelColor;

  .settings-wrapper {
    height: 100%;
  }

  .settings-container {
    display: flex;
    align-items: start;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 0 16px;

    @media(max-width: $laptopXSmallPoint) {
      justify-content: center;
    }

    &:after {
      flex: 1;
      content: '';

      @media(max-width: $laptopMediumPoint) {
        flex: 0;
      }
    }

    .settings-menu-wrapper {
      flex: 1;

      @media(max-width: $laptopMediumPoint) {
        flex: 0;
      }

      @media(max-width: $laptopXSmallPoint) {
        flex: 1;
      }

      .settings-menu {
        display: flex;
        flex-direction: column;
        width: 220px;
        padding: 8px;
        border: 1px solid #E6E6E6;
        border-radius: 16px;

        @media(max-width: $laptopXSmallPoint) {
          max-width: 328px;
          width: 100%;
          margin: 0 auto;
        }
      }
    }

    .settings-tab-view {
      max-width: 336px;
      width: 100%;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .invalid-feedback {
      margin-left: 12px;
    }

    & > div:last-child {
      margin-bottom: 0;
    }
  }

}