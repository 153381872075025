.crm-info {
  height: 100%;
  max-height: calc(100% - 75px);

  $this: &;

  &-info {
    height: 100%;

    & > .vb-dragger {
      right: -3px;

      @media(max-width: $phonePoint) {
        width: 0.9em;
        max-height: calc(100% - 40px);
      }
    }

    .crm-info-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 12px;
      overflow-y: scroll;
      overflow-x: hidden;
      width: calc(100% - 15px);
      padding-right: 20px;

      @media(max-width: $laptopPoint) {
        width: calc(100% - 9px);
      }

      @media(max-width: $phonePoint) {
        margin-top: 10px;
      }

      .crm-info-item {
        display: flex;
        flex-direction: column;

        @media(max-width: $laptopPoint) {
          padding-right: 15px;
        }

        &:last-child {
          margin-bottom: 15px;
        }

        .crm-contact-info-wrapper {
          padding: 6px;
          margin-bottom: 12px;
          position: relative;

          @media(max-width: $phonePoint) {
            margin-bottom: 2em;
          }

          &:empty {
            padding: 0;
            margin: 0;
          }

          .crm-info-spinner-wrapper {
            width: 100%;
            display: none;
          }

          &.expanded {
            .crm-contact-expand-wrapper {
              display: block;
            }

            .crm-info-spinner-wrapper {
              width: 100%;
              display: flex;
            }
          }

          .crm-contact-expand-wrapper {
            display: none;
            padding-left: 50px;

            @media(max-width: $phonePoint) {
              padding-left: 3.4em;
            }

            .open-in-crm-button {
              margin-top: 10px;
            }

            .log-call-note {
              margin-bottom: 0;
              margin-top: 10px;
              padding-right: 5px;

              input {
                box-sizing: border-box;
              }
            }

            .log-call-btn-wrapper {
              width: initial;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }

  &-no-enabled-crms {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    color: $darkGray;

    @media(max-width: $phonePoint) {
      font-size: 1.4em;
    }

    p {
      display: block;
      text-align: center;

      a {
        color: $mainBlue;
        cursor: pointer;
      }
    }
  }

  &-spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 56px);

    @media(max-width: $phonePoint) {
      height: calc(100% - 2.1em);
    }
  }

  &.inline {
    max-height: 100%;

    #{$this}-info {
      max-height: 100%;
      width: 100%;
      height: fit-content;

      .crm-info-wrapper {
        margin-bottom: 0;
        padding-right: 15px;
        max-height: 100%;
        overflow: unset;

        .crm-info-item {
          margin-bottom: 0;
          padding-right: 0;

          &:last-child {
            margin-bottom: 5px;
          }

          .crm-contact-info-wrapper {
            margin-left: 16px;
            margin-bottom: 24px;
            padding: 0;

            &:empty {
              margin: 0;
            }

            .crm-contact-expand-wrapper {
              padding-left: 35px;

              .log-call-note {
                input {
                  max-width: 336px;
                  background-color: #FFFFFF;
                }
              }

              .log-call-btn-wrapper {
                width: 100%;
              }
            }
          }
        }
      }
    }

    #{$this}-no-enabled-crms {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      font-size: 12px;
      margin-left: 16px;
      padding-top: 12px
    }
  }
}