.softphone-state-default {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 12px;
  padding: 16px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background: linear-gradient(0deg, rgba(60, 91, 245, 0.16), rgba(60, 91, 245, 0.16)),
              linear-gradient(0deg, rgba(0, 0, 0, 0.84), rgba(0, 0, 0, 0.84)), #FFFFFF;

  @include is-tablet-or-sm-mobile {
    padding: 12px;
  }

  &-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    row-gap: 16px;
    width: 100%;
    flex: 1;

    @include is-tablet-or-sm-mobile {
      row-gap: 12px;
    }
  }

  &-top-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 12px;
    width: 100%;
  }

  &-center-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    row-gap: 12px;
    width: 100%;
    flex: 1;
  }

  &-bottom-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    align-items: flex-start;
    width: 300px;
    height: 105px;
    position: relative;

    @include is-tablet-or-sm-mobile {
      width: 216px;
      height: 84px;
    }
  }

  &-bottom-col {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
  }

  &-integrations-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    row-gap: 12px;
    height: 72px;

    @include is-tablet-or-sm-mobile {
      row-gap: 8px;
      height: 65px;
    }
  }

  .internal-number-label {
    font-size: 12px;
    font-weight: 500;
    color: $white;

    @include is-tablet-or-sm-mobile {
      font-size: 10px;
    }
  }
}