.contact-popup {

  .contact-popup-avatar-section {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    column-gap: 10px;

    .avatar-container {
      margin: 0;
      margin-top: 6px;

      .avatar {
        height: 95px;
        width: 95px;
        font-size: 38px;
        border-radius: 50%;
        background-color: $mainBlue;
        color: $panelColor;
      }
    }
  }

  .selection {
    color: $darkGray;

    .selection-rendered {
      label {
        opacity: 1;
      }

      .name {
        font-weight: 400;
      }
    }
  }

  .contact-default-callerid-value {
    font-size: 12px;
    color: $darkGray;
  }

  .contact-phonebook-value {
    font-size: 12px;
    color: $darkGray;
  }

  .invalid-feedback {
    padding-left: 5px;
    height: 12px;
  }
}