.app-unavailable-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  row-gap: 48px;
  padding: 15px;

  @media(max-width: $laptopPoint) {
    row-gap: 42px;
  }

  @media(max-width: $tabletPoint) {
    row-gap: 36px;
  }

  @media(max-width: $phonePoint) {
    row-gap: 32px;
  }

  &-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 18px;

    @media(max-width: $laptopPoint) {
      row-gap: 16px;
    }

    @media(max-width: $tabletPoint) {
      row-gap: 12px;
    }

    @media(max-width: $phonePoint) {
      row-gap: 10px;
    }

    // default styles for p, li, ul
    p, li {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;

      @media(max-width: $tabletPoint) {
        font-size: 16px;
        line-height: 24px;
      }

      @media(max-width: $phonePoint) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    ul {
      margin-left: 25px;
      margin-bottom: 15px;
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    column-gap: 32px;
    row-gap: 32px;

    @media(max-width: $phonePoint) {
      flex-direction: column;
    }
  }
}