.phonebooks-table-row {
  border-top: 1px solid rgba($mainBlue, 0.07);

  &-cell {
    padding: 8px;

    &:first-child {
      padding-left: 16px;
    }

    &:last-child {
      padding-right: 16px;
    }
  }

  &:hover {
    background-color: rgba($mainBlue, 0.04);

    + .base-table-expanded-row {
      background-color: rgba($mainBlue, 0.04);
    }
  }
}