.call-parking-item {
  margin-bottom: 16px;
  margin-right: 16px;

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 16px;
    flex-wrap: wrap;

    h3 {
      font-weight: 600;
      font-size: 18px;
      margin-right: 10px;
    }

    .chip {
      margin-right: 6px;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 16px;

    > .base-table {
      flex-basis: calc(50% - 8px);
    }

    .no-busy-slots {
      font-weight: 500;
      font-size: 16px;
      color: $textGray;
    }
  }

  &.one-column {
    margin-right: 0;

    .call-parking-item-content {
      margin-left: 0;
      flex-direction: column;

      > .base-table {
        flex-basis: 100%;
      }

      .no-busy-slots {
        margin-left: 16px;
      }
    }
  }
}