.base-tab-selector {
  display: flex;
  justify-content: space-between;
  width: fit-content;
  overflow: hidden;
  height: 28px;
  padding: 3px;
  border-radius: 8px;
  background-color: $lightGray;

  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    margin-right: 3px;
    padding: 0 10px;
    border-radius: 5px;
    background-color: transparent;
    cursor: pointer;
    transition: .4s;

    &:last-child {
      margin-right: 0;
    }

    &-selected {
      background-color: $white;
    }

    span {
      font-weight: 600;
      font-size: 12px;
    }
  }
}