.base-dropdown {
  &-content {
    padding: 6px;
    border-radius: 12px;
    background: white;
    box-shadow: 0px 12px 24px rgba(65, 65, 65, 0.24);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: 999;
    transition: opacity .2s ease-in-out;

    &-visible {
      visibility: visible;
      opacity: 1;
    }
  }

  &-scroll-wrapper {
    .scroll {
      max-height: inherit;
    }
  }
}