.base-popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .blackout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(60, 91, 245, 0.04), rgba(60, 91, 245, 0.04)), rgba(0, 0, 0, 0.16);
    z-index: 100;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: fit-content;
    padding-bottom: 12px;
    margin-bottom: 6px;
    position: relative;

    &.base-popup-title-center {
      .title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }

      .close-button {
        margin-left: auto;
      }
    }

    .title {
      font-size: 18px;
      font-weight: 600;
      color: $darkGray;
    }
  }

  &-content {
    position: absolute;
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    background-color: $panelColor;
    z-index: 101;
    max-width: 100vw;
    height: auto;
    font-size: 12px;
    font-weight: 500;
  }

  &-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &-left {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &-bottom {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 40px;
    position: relative;
    padding-top: 12px;
    margin-top: 18px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -12px;
      width: calc(100% + 24px);
      height: 1px;
      background-color: $borderGray;
    }

    button {
      margin-right: 16px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}