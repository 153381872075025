.call-parking {
  .service-tab-header {
    display: flex;

    .select-call-parking {
      max-width: 220px;
      margin-right: 12px;
      font-weight: 600;
      font-size: 12px;

      @media(max-width: $laptopXSmallPoint) {
        width: auto;
      }

      .selection-dropdown {
        width: 220px;
      }
    }
  }

  &-no-available {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
}