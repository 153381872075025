.active-call-keypad {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 300px;
  height: 100%;
  max-height: 365px;
  margin-top: auto;

  @include is-tablet-or-sm-mobile {
    max-height: 300px;
  }

  &-tab-selector {
    width: 100%;
    margin-bottom: 8px;
  }

  .dialpad .number-input-container, .active-call-keypad-search-input{
    margin-bottom: 8px;
  }
}