.active-call-keypad-speed-dial-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 340px;
  height: 100%;
  min-height: 0;
  position: relative;

  &::before, &::after {
    content: '';
    position: absolute;
    width: inherit;
    height: 24px;
    z-index: 99;
  }

  &::before {
    background: linear-gradient(0deg, rgba(44, 49, 73, 0) 0%, #2C3149 100%);
    top: 0;
  }

  &::after {
    bottom: 0;
    background: linear-gradient(180deg, rgba(44, 49, 73, 0) 0%, #2C3149 100%);
  }

  &.hide-top-shadow {
    &::before {
      display: none;
    }
  }

  &.hide-bottom-shadow {
    &::after {
      display: none;
    }
  }

  ul {
    overflow-y: auto;
    overflow-x: hidden;
    color: $white;
    width: 100%;

    &::-webkit-scrollbar {
      width: 0px;
    }

    li {
      cursor: pointer;
      margin-bottom: 6px;
      border-radius: 24px;
      background: rgba(255, 255, 255, 0.04);
      transition: background 0.2s linear;

      &:hover {
        background: rgba(255, 255, 255, 0.08);
      }

      &.selected-item {
        background: rgba(255, 255, 255, 0.16);
      }
    }
  }

  .no-results {
    margin: auto 0;
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    color: $white;

    @include is-tablet-or-sm-mobile {
      font-size: 13px;
    }
  }
}