.base-tooltip {
  display: inline-block;

  &-default-activator {
    display: flex;
    justify-content: center;
    position: relative;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $mainBlue;
    color: $white;
    font-size: 11px;
    line-height: 14px;
    cursor: default;
  }

  &-content {
    color: $white;
    padding: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    border-radius: 8px;
    background: $blackTransparent;
    transition: opacity .2s ease-in-out;
    white-space: normal;
  }

}