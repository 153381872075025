.active-call-keypad-search-input {
  position: relative;
  width: 100%;

  input {
    font-size: 16px;
    font-weight: 500;
    height: 44px;
    border-radius: 22px;
    line-height: 24px;
    letter-spacing: 0px;
    transition: background 0.2s linear;

    @include tablet-media  {
      padding: 0 40px;
      border-radius: 20px;
      height: 40px;
    }
  }
}