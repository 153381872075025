.sms-dialog {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  position: relative;

  .dialog-items-wrapper {
    padding-top: 16px;

    .scroll-wrapper {
      .base-spinner {
        display: block;
        margin: 0 auto;
        margin-bottom: 12px;
      }

      .sentinel, .unread-messages-separator {
        height: 0;
      }

      .dialog-item {
        width: 100%;
        height: fit-content;

        .item-received-on {
          font-size: 12px;
          font-weight: 600;
          margin: 0 auto;
          width: fit-content;

          .received-on {
            color: $textGray;
          }

          .number, .name {
            color: $darkGray;
          }
        }

        .item-received-on, .item-date-label, .item-message {
          margin-bottom: 14px;
        }

        .item-date-label {
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  .spinner-wrapper {
    height: 100%;
  }

  .input-area {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 16px;
    border-top: 1px solid $borderGray;

    @media(max-width: $tabletSmallPoint) {
      padding: 12px;
    }

    .base-textarea {
      flex: 1;
      margin-right: 5px;
    }

    .controls {
      display: flex;
      justify-content: space-between;
      position: relative;

      button {
        margin-right: 3px;
      }

      .test {
        width: 20px;
        height: 20px;
        border: 3px solid red;
      }

      .sms-characters-counter {
        position: absolute;
        bottom: -12px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 10px;
        white-space: nowrap;

        @media(max-width: $tabletSmPoint) {
          bottom: -10px;
        }
      }
    }

    .no-sms-numbers {
      width: 100%;
      font-size: 10px;
      font-weight: 500;
      padding: 4px 12px;
      border-radius: 6px;
      margin-top: 12px;
      background-color: $mainOrange;
      color: $white;
    }
  }
}