.dnd-switcher-status {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;

  &-disabled {
    filter: brightness(0.7);
  }

  &-size-bg {
    width: 18px;
    height: 18px;
    font-size: 13px;

    // override style
    .base-spinner {
      width: 13px;
      height: 13px;
      border-width: 2px;
    }
  }

  &-size-sm {
    width: 12px;
    height: 12px;
    font-size: 10px;

    .icon-rectangle {
      font-size: 8px;
    }

    // override style
    .base-spinner {
      width: 8px;
      height: 8px;
      border-width: 1.5px;
    }
  }

  &-color-green {
    background-color: $mainGreen;
    color: $white;
  }

  &-color-red {
    background-color: $mainRed;
    color: $white;
  }

  &-color-inverted-red {
    background-color: $white;
    color: $mainRed;
  }
}