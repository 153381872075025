.person {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;
  width: 80%;

  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 2px;
  }

  .caller-id-name {
    font-size: 12px;
    line-height: 18px;
    color: $darkModeText;
  }

  &-size-default {
    row-gap: 16px;
  }

  &-size-small {
    row-gap: 10px;
  }
}