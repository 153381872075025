.voicemail-item-table-row {
  border-top: 1px solid $borderGray;
  position: relative;

  &-cell {
    padding: 11px;
    font-size: 12px;
    font-weight: 500;
    color: $darkGray;
  }

  .overlap-cell {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: #FFFFFF;
    left: 0;
    padding-right: 32px;

    .audio-player {
      margin-right: 12px;
    }
  }

  .voicemail-message-status {
    &-read {
      font-weight: 600;
      color: $mainGreen;
    }

    &-unread {
      font-weight: 600;
      color: $mainRed;
    }

    &-date {
      display: block;
      color: $mainGray;
      font-size: 10px;
      font-weight: 500;
    }
  }

  .voicemail-message-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 21px;

    > .base-button {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }
    }

    .call-btn {
      &:hover {
        background-color: $mainGreen !important;
      }
    }

    .audio-player-wrapper {
      max-width: 400px;
      width: 100%;
    }
  }

  &:hover, &.base-table-row-selected {
    background-color: rgba($mainBlue, 0.04);

    + .base-table-expanded-row {
      background-color: rgba($mainBlue, 0.04);
    }
  }
}