.reset-password {
  h2 {
    margin-bottom: 24px;
    font-size: 24px;
  }

  &-complexity-recommendation {
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
    margin-bottom: 16px;
  }

  &-rules-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &-rule {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }

  &-success {
    max-width: 386px;
    margin: 0 auto;
    text-align: center;

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
      color: $darkGray;

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}