.alert-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 56px;
    border-radius: 12px;
    padding: 8px;
    position: relative;

    &.success {
      background-color: $mainGreen;
      box-shadow: 0px 12px 24px rgba($mainGreen, 0.24);

      button {
        color: $mainGreen;
      }
    }

    &.info {
      background-color: $mainBlue;
      box-shadow: 0px 12px 24px rgba($mainBlue, 0.24);

      button {
        color: $mainBlue;
      }
    }

    &.warning {
      background-color: $mainOrange;
      box-shadow: 0px 12px 24px rgba($mainOrange, 0.24);

      button {
        color: $mainOrange;
      }
    }

    &.error {
      background-color: $mainRed;
      box-shadow: 0px 12px 24px rgba($mainRed, 0.24);

      button {
        color: $mainRed;
      }
    }

    .title {
      display: flex;
      align-items: center;
      margin-bottom: 6px;

      span {
        color: $white;
        font-size: 18px;
        line-height: 18px;
        margin-right: 4px;
      }

      p {
        color: $white;
        font-size: 14px;
        line-height: 18px;
        font-weight: 600;

        &::first-letter {
          text-transform: capitalize;
        }
      }
    }

    .icon-clear {
      position: absolute;
      right: 8px;
      top: 8px;
      color: $white;
      font-size: 18px;
      line-height: 18px;
      cursor: pointer;
      transition: opacity 0.2s linear;

      &:hover {
        opacity: 0.6;
      }
    }

    p {
      color: $white;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      margin: auto 0;
      text-align: center;
      max-width: 600px;
      white-space: pre-wrap;

      @media(max-width: $tabletSmPoint) {
        max-width: 252px;
      }
    }

    button {
      margin-top: 10px;
    }
  }