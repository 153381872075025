.phonebooks-tab {
  height: 100%;
  position: relative;

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    margin-bottom: 8px;
    flex-wrap: wrap;
    row-gap: 12px;

    .select-phonebook {
      max-width: 220px;
      margin-right: 8px;
    }

    .find-contact {
      max-width: 220px;

      @media(max-width: $laptopXSmallPoint) {
        max-width: 100%;
      }
    }

    .base-tooltip {
      margin-right: 8px;
    }

    .search-chip {
      margin-right: auto;

      .chip-button-close {
        margin-left: 8px;
      }
    }
  }

  &-table {
    min-height: 0;
  }
}
