.person-collapsed {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background: rgba(255, 255, 255, 0.04);
  transition: background 0.2s linear;

  .call-status-label {
    font-size: 12px;
  }

  &-on-hold {
    opacity: 0.48;

    &:hover {
      background: rgba(255, 255, 255, 0.06);
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.08);
  }

  .avatar-container {
    margin: 0;
  }

  &-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    min-width: 0;
  }

  &-call-info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    row-gap: 4px;
    margin-left: auto;
  }

  &-size-default {
    column-gap: 12px;
    height: 86px;
    padding: 12px;
    border-radius: 20px;
  }

  &-size-small {
    height: 70px;
    border-radius: 16px;
    padding: 8px;
    column-gap: 8px;
  }
}