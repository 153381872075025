.softphone-state-incoming-incoming {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &-status-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  &-sessions-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    row-gap: 60px;
    margin: auto 0;
  }

  &-session {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 12px;
  }

  &-session-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 136px;
  }
}