.phonebooks-phone-number-cell {
  font-weight: 600;
  font-size: 12px;
  box-sizing: border-box;

  &-number {
    background-color: rgba($black, 0.03);
    border-radius: 10px;
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
    height: 26px;
    transition: .4s;

    &:hover {
      background-color: $mainGreen;
      color: $white;

      .phone-icon {
        color: $white;
      }
    }

    .phone-icon {
      color: $mainGray;
      font-size: 18px;
      margin-right: 3px;

      @media(max-width: $laptopXSmallPoint) {
        display: none;
      }
    }

    .phone-number {
      width: 100%;
      padding-top: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}