.create-contact {
  padding: 6px;

  &-header {
    display: flex;
    align-items: center;

    img {
      width: 34px;
      height: 34px;
      margin-right: 16px;

      @media(max-width: $phonePoint) {
        width: 2.7em;
        height: 2.7em;
        margin-right: 0.7em;
      }
    }

    span {
      font-size: 18px;
      line-height: 18px;
      font-weight: 600;
      color: $darkGray;

      @media(max-width: $phonePoint) {
        font-size: 1.45em;
        line-height: 1em;
      }
    }
  }

  &-offer, &-create, &-failed {
    margin-left: 50px;
    margin-top: 16px;
    margin-bottom: 24px;

    @media(max-width: $phonePoint) {
      margin-left: 3.4em;
      margin-bottom: 2em;
    }
  }

  &-offer, &-failed {
    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      color: $darkGray;
    }

    button {
      margin-top: 12px;
    }
  }

  &-create {
    input {
      box-sizing: border-box;
    }

    .buttons-group {
      display: flex;

      button {
        display: flex;
        margin-right: 12px;
      }
    }
  }

  &.inline {
    margin-left: 16px;
    padding: 0;

    .create-contact-header {
      img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
      }

      span {
        font-size: 12px;
        line-height: 12px;
      }
    }

    .create-contact-offer, .create-contact-create, .create-contact-failed {
      margin-left: 35px;
      margin-top: 8px;
      margin-bottom: 24px;
    }

    .create-contact-create {
      max-width: 336px;
    }
  }
}