.base-spinner {
  display: inline-block;
  border-style: solid;
  border-radius: 50%;
  animation: spinner-rotate ease-in-out 1s infinite;
  -webkit-animation: spin 1s ease-in-out infinite;


  @keyframes spinner-rotate {
    from {
      transform: rotateZ(0);
    }

    to {
      transform: rotateZ(1turn);
    }
  }

  &-size-big {
    width: 50px;
    height: 50px;
    border-width: 3px;
  }

  &-size-medium {
    width: 25px;
    height: 25px;
    border-width: 2px;
  }

  &-size-small {
    width: 15px;
    height: 15px;
    border-width: 1.5px;
  }

  &-color-blue {
    border-color: $mainBlue;
    border-top-color: transparent;
  }

  &-color-white {
    border-color: $white;
    border-top-color: transparent;
  }

  &-color-red {
    border-color: $mainRed;
    border-top-color: transparent;
  }

  &-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}