.settings-tab-device-management {
  font-size: 12px;
  font-weight: 500;

  .ring-mode {
    margin-bottom: 12px;

    .title {
      display: flex;
      margin-bottom: 10px;

      > span {
        display: inline-block;
        margin-right: 8px;
      }
    }
  }

  .twinning-settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;

    p {
      color: $darkGray;
      margin-right: 12px;
    }
  }

  .call-confirm-container {
    display: flex;

    .call-confirm-switch {
      margin-bottom: 12px;
      margin-right: 8px;
    }
  }

  .follow-me-container {
    position: relative;
    padding: 12px;
    padding-top: 35px;
    border-radius: 12px;
    background-color: $lightGray;

    .scale-container {
      display: flex;
      justify-content: space-between;
      position: absolute;
      top: 12px;
      left: 152px;
      width: 115px;
      font-size: 11px;
      font-weight: 500;
      color: $textGray;

      .sec {
        position: absolute;
        left: calc(100% + 4px);
      }
    }

    .add-external-number-btn {
      margin-top: 16px;
    }
  }
}