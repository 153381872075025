.base-input {
  position: relative;

  &-wrapper {
    position: relative;

    .tooltip {
      display: none;
    }

    &:hover {
      .tooltip {
        display: block;
      }
    }

    label {
      position: absolute;
      top: 12px;
      left: 12px;
      font-weight: normal;
      font-size: 12px;
      color: $darkGray;
      transition: .4s;
      opacity: 0;
    }

    input {
      width: 100%;
      border-radius: 8px;
      font-weight: 500;
      font-size: 12px;
      padding: 10px 12px;
      height: 40px;
      outline: none!important;

      &::placeholder {
        color: $textGray;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent
      }

      &:focus::-moz-placeholder {
        color: transparent
      }

      &:focus:-moz-placeholder {
        color: transparent
      }

      &:focus:-ms-input-placeholder {
        color: transparent
      }

      &:disabled {
        background-color: $lightGray;
        border: none;
        color: $mainGray;
        -webkit-text-fill-color: $mainGray;
        opacity: 1;
  
        & + label {
          color: $mainGray;
        }
      }
    }

    input:focus + label {
      top: 6px;
      opacity: 1;
      font-size: 10px;
    }

    .icon-wrapper {
      position: absolute;
      right: 12px;
      font-size: 16px;
      top: 50%;
      transform: translateY(-50%);

      .icon {
        position: unset;
        height: 5px;
        width: 9px;
      }

      &.base-input-icon-color-gray {
        color: $mainGray;
        cursor: pointer;
      }
    }

    .icon-wrapper-password {
      cursor: pointer;
    }
  }

  &.base-input-filled {
    label {
      top: 6px;
      opacity: 1;
      font-size: 10px;
    }
  }

  &.base-input-filled.base-input-has-label {
    input {
      padding: 18px 40px 2px 12px;
    }
  }

  &.base-input-has-label {
    input {
      padding: 10px 40px 10px 12px;
    }

    input:focus {
      padding: 18px 40px 2px 12px;
    }
  }

  &.base-input-rounded {
    input {
      border-radius: 18px;
    }
  }

  &.base-input-has-icon, &.base-input-type-password {
    input {
      padding-right: 35px;
    }
  }

  &.base-input-color-default {
    input {
      border: 1px solid $borderGray;
      color: $darkGray;

      &:hover {
        border-color: $mainHover;
      }

      &:active {
        border-color: $mainHover;
        box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
      }

      &:focus {
        border-color: $mainBlue;
        box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
      }
    }
  }

  &.base-input-color-login {
    input {
      color: $darkGray;
      background: #F3F6FF;
      border: 2px solid #F3F6FF;

      &:hover,
      &:active {
        background: #EBEFFB;
        border: 2px solid #EBEFFB;
      }

      &:focus {
        background: #E0E6F9;
        border: 2px solid #E0E6F9;
      }
    }
  }

  &.base-input-color-dark {
    color: $white;

    input {
      color: inherit;
      background: rgba($white, 0.04);
      border: none;
      text-align: center;

      &::placeholder {
        color: $darkModeText;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent
      }

      &:focus::-moz-placeholder {
        color: transparent
      }

      &:focus:-moz-placeholder {
        color: transparent
      }

      &:focus:-ms-input-placeholder {
        color: transparent
      }

      &:hover {
        background: rgba($white, 0.08);
      }

      &:active {
        background: rgba($white, 0.12);
      }

      &:focus {
        background: rgba($white, 0.16);
      }
    }
  }

  &.base-input-error.base-input-color-login {
    input {
      border: 2px solid $mainRed;
      background: rgba($mainRed, 0.12);
    }
  }
}