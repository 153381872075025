.contact-info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  max-height: 25px;
  height: 100%;
  width: 100%;
  min-width: 0;

  .base-tooltip {
    height: 100%;
    min-width: 0;
  }

  .base-dropdown {
    height: 100%;
    min-width: 0;

    &-activator-container {
      height: 100%;
    }
  }

  &-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  &-primary {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 1.4;
    font-weight: 500;
  }

  &-secondary {
    font-size: 10px;
    line-height: 1.4;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-dark-color {
    .contact-info-primary, .contact-info-secondary {
      color: $white;
    }
  }

  &-light-color {
    .contact-info-primary {
      color: $darkGray;
    }

    .contact-info-secondary {
      color: $mainGray;
    }
  }
}