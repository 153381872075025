.base-phone-number-input {
  .form-group {
    position: relative;

    .phone-number-input {
      padding-left: 60px !important;

      ~ label {
        left: 60px;
      }
    }

    .invalid-feedback {
      height: 12px;
    }
  }
}