.settings-tab-integrations {
  .crm-integrations-section {
    width: 100%;

    h4 {
      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: $darkGray;
      margin-bottom: 7px;
    }

    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
    }

    .how-to-enable-integrations {
      font-size: 12px;
      white-space: pre-wrap;
    }

    .crm-integrations-group {
      width: 100%;
      position: relative;
      margin: 16px 0;
    }

    .crm-integration {
      position: relative;
      min-height: 40px;
      width: 100%;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid $borderGray;
      padding: 11px 13px;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        width: 48px;
        height: 16px;
      }
    }

    .automation-section {
      .title {
        display: inline-block;
        margin-bottom: 16px;
        font-size: 18px;
        font-weight: 600;
      }

      p {
        display: inline-block;
        margin-bottom: 16px;
      }

      .crm-name {
        font-weight: 500;
        font-size: 12px;
        color: #292929;
        line-height: 14px;
        margin-left: auto;
        margin-right: 8px;
      }
    }
  }
}