.person-name-number-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  .name {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $white;
    line-height: 1.5;
  }

  .number {
    font-weight: 600;
    color: $white;
    cursor: pointer;
    line-height: 1.5;
  }

  &-name-exist {
    .name {
      font-weight: 600;
    }

    .number {
      font-weight: 500;
      color: $darkModeText;
    }
  }

  &-align-left {
    align-items: flex-start;
    text-align: left;
  }

  &-align-center {
    align-items: center;
    text-align: center;
  }

  &-align-right {
    align-items: flex-end;
    text-align: right;
  }
}