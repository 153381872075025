.form-group {
  position: relative;
  margin-bottom: 18px;
  width: 100%;

  label {
    position: absolute;
    top: 12px;
    left: 12px;
    font-weight: normal;
    font-size: 12px;
    color: $darkGray;
    transition: .4s;
    opacity: 0;
  }

  & input:focus + label {
    top: 6px;
    opacity: 1;
    font-size: 10px;
  }

  & select:focus + label {
    top: 6px;
    opacity: 1;
    font-size: 10px;
  }

  > .icon {
    color: $mainGray;
    position: absolute;
    top: 12px;
    right: 16px;
    height: 5px;
    width: 9px;
  }

  input, select {
    width: 100%;
    border: 1px solid $borderGray;
    border-radius: 8px;
    font-weight: 500;
    font-size: 12px;
    color: $darkGray;
    padding: 12px 13px 11px 13px;
    outline: none!important;

    &.has-label {
      padding: 12px 40px 11px 12px;
    }

    &::placeholder {
      color: $textGray;
    }

    &:hover {
      border-color: $mainHover;
    }

    &:active {
      border-color: $mainHover;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:focus {
      border-color: $mainBlue;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:disabled {
      background-color: $lightGray;
      border: none;
      color: $mainGray;
      -webkit-text-fill-color: $mainGray;
      opacity: 1;

      & + label {
        color: $mainGray;
      }
    }

    &:focus::-webkit-input-placeholder {
      color: transparent
    }

    &:focus::-moz-placeholder {
      color: transparent
    }

    &:focus:-moz-placeholder {
      color: transparent
    }

    &:focus:-ms-input-placeholder {
      color: transparent
    }
  }

  &.filled label {
    top: 6px;
    opacity: 1;
    font-size: 10px;
  }

  &.filled input.has-label {
    padding: 18px 40px 5px 12px;
  }

  & input.has-label:focus {
    padding: 18px 40px 5px 12px;
  }

  &.filled select.has-label {
    padding: 18px 40px 5px 12px;
  }

  & select.has-label:focus {
    padding: 18px 40px 5px 12px;
  }

  .invalid-feedback {
    position: absolute;
    bottom: -15px;
    font-size: 10px;
    color: $mainRed;
  }
}

.tip {
  position: relative;
  cursor: pointer;

  .tip-icon {
    background-color: $mainBlue;
    height: 14px;
    width: 14px;
    border-radius: 50%;
    font-weight: 600;
    font-size: 11px;
    text-align: center;
    color: $panelColor;
  }

  .tip-text {
    position: absolute;
    display: none;
    opacity: 0;
    transition: .4s;
    left: 18px;
    top: -100%;
    background-color: $darkGray;
    color: $panelColor;
    font-weight: 500;
    font-size: 12px;
    padding: 8px;
    border-radius: 8px;
    line-height: 15px;
    z-index: 200;

    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 100%;
      width: 0;
      height: 0;
      border-bottom: 6px solid $darkGray;
      border-top: 6px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    &-bottom {
      left: 50%;
      top: calc(100% + 6px);
      transform: translateX(-50%);
    }

  }

  &:hover {
    .tip-text {
      display: block;
      opacity: 1;
    }
  }
}

.vb > .vb-dragger {
  z-index: 5;
  width: 13px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0,0,0,0);
  transform: rotate3d(0,0,0,0);
  -webkit-transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
  transition:
          background-color 100ms ease-out,
          margin 100ms ease-out,
          height 100ms ease-out;
  background-color: rgba($mainBlue,0.24);
  margin: 5px 4px 5px 5px;
  border-radius: 20px;
  height: 100%;
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba($mainBlue,0.24);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba($mainBlue,0.24);
  //margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba($mainBlue,0.24);
  //margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba($mainBlue,0.24);
}

.base-tooltip-activator {
  display: inline-block;
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: $mainBlue;

  &::before {
    content: '?';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 11px;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
}

.btn-filter {
  .counter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    min-width: 18px;
    height: 18px;
    margin-left: 8px;
    border-radius: 50%;
    background-color: $white;
    color: $mainBlue;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
  }
}