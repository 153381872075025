.auth-input {
  .label {
    font-size: 14px;
    line-height: 14px;
    font-weight: 600;
    color: $darkGray;
    margin-bottom: 8px;
    display: block;
  }

  input {
    font-size: 14px !important;
  }
}