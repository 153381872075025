.softphone-dialpad {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 16px;
  padding: 16px;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  @include is-tablet-or-sm-mobile {
    row-gap: 12px;
    padding: 12px;
  }

  &-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    position: relative;

    &:first-child {
      margin-top: auto;
    }

    &:last-child {
      margin-bottom: auto;
    }
  }

  .caller-id-label {
    font-size: 12px;
    font-weight: 500;
    color: $darkGray;

    @include is-tablet-or-sm-mobile {
      font-size: 11px;
    }
  }

  .internal-number-label {
    font-size: 12px;
    font-weight: 500;
    color: $darkGray;

    @include is-tablet-or-sm-mobile {
      font-size: 11px;
    }
  }

  // override default dialpad styles
  .dialpad {
    .number-input-container {
      margin-bottom: 38px;
    }
  }

  .contact-name {
    position: absolute;
    left: 50%;
    top: calc(100% + 15px);
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 500;
    color: $textGray;
    width: 100%;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .connect-label {
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -25px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    background: radial-gradient(3% 100% at 50% 50%, #38B955 0%, rgba(56, 185, 85, 0.32) 100%);
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: connect-label 3s infinite linear;

    @keyframes connect-label {
      from {
        background-position: right top;
        background-size: 200% 100%;
      }
      to {
        background-position: left top;
        background-size: 200% 100%;
      }
    }
  }
}