@mixin tablet-media {
  @media (max-width: $tabletPoint) and (min-width: $tabletSmPoint + 1) {
    @content;
  }
}

@mixin tablet-media-height {
  @media (max-width: $tabletPoint) and (min-width: $tabletSmPoint + 1) and (min-height: 540px) {
    @content;
  }
}

@mixin is-tablet-or-sm-mobile {
  @media (max-width: $phoneSmallPoint), (max-width: $tabletPoint) and (min-width: $tabletSmPoint + 1) {
    @content;
  }
}