.two-factor-auth-setup-popup {
  .base-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 12px;

    .base-popup-header {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    .base-popup-actions {
      margin-top: 0;
    }
  }

  p {
    width: 100%;
    font-weight: 500;
    color: $darkGray;
    line-height: 16px;
    font-size: 12px;
  }

  &-download-buttons {
    display: flex;
    width: 100%;
    justify-content: center;
    column-gap: 12px;
  }

  &-secret {
    height: 14px;
    font-size: 14px;
    line-height: 1;
    font-weight: 600;
    color: $darkGray;
  }

  &-password-input {
    input {
      height: 28px !important;
    }
  }
}