.integrations-activate-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #FFFFFF;

  .integrations-activate-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 32px;
    font-weight: 600;
    padding: 0 15px;
    text-align: center;

    @media(max-width: $laptopPoint) {
      line-height: 24px;
    }

    @media(max-width: $tabletSmPoint) {
      line-height: 20px;
    }

    @media(max-width: $phonePoint) {
      line-height: 24px;
    }

    h1 {
      font-size: 32px;
      color: $darkGray;
      margin-bottom: 16px;

      @media(max-width: $laptopPoint) {
        font-size: 24px;
        margin-bottom: 12px;
      }

      @media(max-width: $tabletSmPoint) {
        font-size: 20px;
      }

      @media(max-width: $phonePoint) {
        font-size: 18px;
        margin-bottom: 8px;
      }
    }

    p {
      font-size: 24px;
      color: $textGray;

      @media(max-width: $laptopPoint) {
        font-size: 20px;
        line-height: 28px;
      }

      @media(max-width: $tabletSmPoint) {
        font-size: 16px;
        line-height: 24px;
      }

      @media(max-width: $phonePoint) {
        font-size: 14px;
        line-height: 18px;
      }
    }

    a {
      color: $mainBlue;
      cursor: pointer;
    }
  }
}