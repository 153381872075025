.base-table-header-item {
  padding: 11px;
  font-size: 12px;
  font-weight: 600;
  color: rgba($black, 0.68);

  &-align-left {
    text-align: left;
  }

  &-align-center {
    text-align: center;
  }

  &-align-right {
    text-align: right;
  }

  &-allow-sort {
    cursor: pointer;
  }

  .icon-sort {
    visibility: hidden;
    font-size: 18px;
  }

  .icon-allow-sort {
    color: rgba($black, 0.38);
  }

  .icon-sorted {
    color: rgba($black, 0.87);
    visibility: visible;
  }

  &:hover {
    .icon {
      visibility: visible;
    }
  }
}