.new-sms-dialog-popup {
  .popup-content {
    width: 400px;

    .from-number-select {
      .tooltip {
        width: 342px;
        bottom: unset;
        top: -75px;
      }
    }
  }

  .form-group.textarea {
    height: 76px;
  }

  .sms-characters-counter {
    margin-left: 12px;
  }
}