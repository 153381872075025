.active-call-keypad-tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 0;

  // override default icon size
  .dial-pad-action-button .icon {
    font-size: 18px;
  }
}