.history-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: $darkModeBlack;

  @include tablet-media {
    width: 25px;
    height: 25px;
  }

  .icon {
    font-size: 16px;
  }

  .icon-call-ingoing {
    color: $mainGreen;
  }

  .icon-call-missed {
    color: $mainRed;
  }

  .icon-call-outgoing {
    color: $mainBlue;
  }
}