.call-settings-record-button {
  &.call-settings-button-active .softphone-button-button {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      animation: flashing-red 2s infinite alternate;

      @include is-tablet-or-sm-mobile {
        width: 16px;
        height: 16px;
      }

      @keyframes flashing-red {
        from {
          background-color: #F24343;
        }
        to {
          background-color: #EB0000;
        }
      }
    }

    .icon {
      display: none;
    }
  }
}