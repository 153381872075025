.base-counter {
  position: relative;

  input {
    height: 28px;
    width: 62px;
    padding-left: 10px;
    padding-right: 28px;
    -moz-appearance: textfield;
    outline: none;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
    border: 1px solid $borderGray;
    color: $textGray;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::placeholder {
      color: $textGray;
    }

    &:hover {
      border-color: $mainHover;
    }

    &:active {
      border-color: $mainHover;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    &:focus {
      border-color: $mainBlue;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }
  }

  .btns-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 18px;
    height: 20px;
    right: 10px;
    top: 4px;

    button {
      outline: none;
      box-shadow: none;
      border: none;
      width: 100%;
      height: 50%;
      font-size: 14px;
      color: $mainGray;
      overflow: hidden;
      //border: 1px solid red;

      .icon {
        display: inline-block;
        transform: translate(-6px, -4px);
      }
    }
  }
}