.voicemails {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  padding-bottom: 16px;

  .service-tab-header {
    > .base-button {
      //margin-bottom: 8px;
    }

    > .base-button, .chip {
      margin-right: 12px;
    }

    .chip-button-close {
      margin-left: 8px;
    }

    .refresh-btn {
      margin-left: auto;
      margin-right: 36px;
    }

    @media(max-width: $laptopPoint) {
      .refresh-btn {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }

  &-no-available {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
    font-size: 16px;
  }
}