.base-list-item {
  display: flex;
  width: 100%;
  padding: 5px 16px 5px 5px;
  min-height: 28px;
  border-radius: 4px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;

  &-selected {
    background: $background;
  }

  &-hover {
    transition: background 0.2s linear;

    &:hover {
      background: $background;
    }
  }
}