.call-history-mobile-expanded-tab-row {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;

  &-title {
    color: $darkGray;
  }

  &-value {
    color: $textGray;
  }
}