.integrations-inline {
  box-sizing: border-box;
  font-size: 11.9px;
  padding: 0 32px 12px 0;
  width: 100%;

  @media(max-width: $phonePoint) {
    padding-top: 12px;
  }

  .log-all-call-btn {
    margin-left: 51px;
  }
}