.sms-chat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 11px 16px;
  cursor: pointer;
  box-sizing: border-box;

  &:hover {
    background: linear-gradient(0deg, rgba(60, 91, 245, 0.02) 0%, rgba(60, 91, 245, 0.02) 100%), #FFF;
  }

  &.selected {
    background: linear-gradient(0deg, rgba(60, 91, 245, 0.04) 0%, rgba(60, 91, 245, 0.04) 100%), #FFF;
  }

  .avatar-container {
    margin: 0;
    margin-right: 12px;
  }

  .name-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-size: 12px;
    min-width: 0;

    .contact-name {
      font-weight: 600;
      color: $darkGray;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 7px;
    }

    .last-message {
      font-weight: 500;
      color: $textGray;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .you {
        color: $mainBlue;
      }

      .draft {
        color: $mainRed;
        font-weight: 600;
      }
    }
  }

  .time-group {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    margin-left: auto;
    text-align: right;

    .last-message-time {
      font-size: 11px;
      font-weight: 500;
      color: $mainGray;
      white-space: nowrap;
    }

    .unread-messages-count {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      margin-top: auto;
      width: fit-content;
      min-width: 18px;
      padding: 0 3px;
      height: 18px;
      border-radius: 50%;
      background-color: $mainBlue;
      font-size: 10px;
      font-weight: 500;
      color: $white;
    }
  }
}