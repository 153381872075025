.active.tab.sms-tab {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .sms-header {
    padding: 16px;
    padding-top: 0;
    border-bottom: 1px solid $borderGray;

    @media(max-width: $tabletSmallPoint) {
      padding-bottom: 11px;
      padding-right: 12px;
      padding-left: 5px;
    }

    .sms-buttons {
      display: flex;

      .search-button{
        border: 1px solid $borderGray;
        margin-right: 12px;
      }
    }

    .find-chat {
      width: 100%;
      margin-left: 10px;
    }

  }

  .left-panel {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: 320px;
    width: 100%;
    height: 100%;
    position: relative;

    @media(max-width: $laptopMediumPoint) {
      max-width: 280px;
    }

    @include tablet-media  {
      max-width: 235px;
    }

    @media(max-width: $tabletSmallPoint) {
      max-width: 100%;
    }

    &::after {
      content: '';
      position: absolute;
      height: calc(100% + 16px);
      width: 1px;
      background-color: $borderGray;
      top: -16px;
      right: 0;
    }

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .icon-add {
        font-size: 14px !important;
      }
    }

    &-header.search-panel {
      justify-content: space-around;
    }

    .sms-chats {
      height: 100%;
      width: 100%;
      max-height: calc(100% - 57px);


      > .vb-dragger > .vb-dragger-styler {
        margin: 5px 4px 5px 5px
      }

      .scroll-wrapper {
        position: relative;
        width: calc(100% + 13px) !important;

        .spinner-wrapper {
          display: flex;
          height: fit-content;
          width: 100%;
          justify-content: center;
          align-items: center;
        }

        &.empty-list {
          .spinner-wrapper {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .spinner-wrapper {
      height: 100%;
      width: 100%;
      position: relative;
    }

    .no-sms-chats {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      h4 {
        font-size: 16px;
        font-weight: 600;
      }

      p {
        padding: 16px;
        font-size: 13px;
        font-weight: 500;
        line-height: 22px;
        text-align: center;
      }
    }

    .nothing-found {
      color: $darkGray;
      font-style: normal;
      font-weight: 600;
    }
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    min-width: 0;

    .right-panel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-width: 0;

      .make-call-button {
        margin-right: 12px;
      }

      .title-wrapper {
        flex: 1;
        min-width: 0;

        h3 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 18px;
          font-weight: 600;
          color: rgba($black, 0.84);
        }
      }

      .right-actions-group {
        display: flex;
        flex-direction: row;
        column-gap: 12px;

        .delete-btn {
          margin-left: auto;

          .icon {
            color: $mainRed;
          }
        }

        .actions-select {
          .icon-dots {
            font-size: 14px !important;
          }
        }
      }

      .left-actions-group {
        display: flex;
        align-items: center;
        min-width: 0;

        .base-tooltip {
          flex: 1;
          min-width: 0;
        }
      }
    }

    .integrations-wrapper {
      max-height: 330px;
      padding-left: 10px;
      border-bottom: 1px solid $borderGray;
      min-height: 50px;

      .integrations-inline {
        padding-right: 0;

        .crm-info-spinner-wrapper {
          margin-top: 12px;
        }
      }
    }

    .sms-dialog-wrapper {
      min-height: 0;
      height: 100%;
    }

    &.select-contact-tip {
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .chip .text {
        font-size: 16px;
      }
    }
  }

  .integrations-popup {
    .base-popup-content {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      max-height: calc(100% - 250px);

      @media(max-width: $phonePoint) {
        max-height: calc(100% - 15em);
      }
    }
  }
}

.sms-dropdown-actions-delete-item {
  color: $mainRed;

  .base-list-item-title {
    color: $mainRed;
  }
}