.menu {
  width: 55%;
  height: 48px;
  color: $darkGray;
  align-items: center;
  justify-content: space-around;

  @media(max-width: $laptopMediumPoint) {
    width: 70%;
  };

  @media(max-width: $tabletLargePoint) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 54px;
    border-top: solid 2px #e8e9f6;
    background-color: $panelColor;
    z-index: 1000;
  }

  &.electron-exist {
    width: 45%;

    @media(max-width: $tabletLargePoint) {
      width: 100%;
    }
  }

  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 100%;

    @media(max-width: $tabletLargePoint) {
      justify-content: space-around;
    }

    .navigation-item {
      height: 100%;
      align-items: center;
      vertical-align: middle;
      padding: 0 12px;
      margin-right: 13px;
      box-sizing: border-box;
      cursor: pointer;
      line-height: 46px;
      font-size: 13px;
      font-weight: 600;
      position: relative;
      transition: .4s;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      &:last-child {
        margin-right: 0;
      }

      @media(max-width: $laptopXSmallPoint) {
        padding: 0 8px;
        margin-right: 8px;
      }

      @media(max-width: $tabletLargePoint) {
        display: inline-block;
        text-align: center;
        font-size: 10px;
        width: 20%;
        color: $mainGray;
        padding: 9px 0;
        margin: 0 5px;
        line-height: 25px;
      }

      .icon {
        display: none;
        font-size: 20px;

        @media(max-width: $tabletLargePoint) {
          display: flex;
          justify-content: center;
        }
      }

      .red-dot {
        margin-left: 5px;
      }

      &.download-app {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: unset;
        width: inherit;

        .icon {
          display: inline-block;
          font-size: 18px;
        }

        @media(max-width: $laptopMediumPoint) {
          .icon, .navigation-item-title {
            display: none;
          }

          .icon.icon-file_download {
            display: inline-block;
          }
        }
      }

      &.softphone-item {
        display: none;
        text-align: center;
        padding: 0;

        @media(max-width: $tabletLargePoint) {
          display: flex;
        }

        > div {
          width: 100%;
          height: 100%;
          padding: 9px 0;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .icon {
          justify-content: center;
        }

        .time {
          color: $darkGray;
          font-weight: 600;
        }
      }

      &.sms-item {
        text-overflow: unset;
      }

      &:after {
        content: '';
        height: 2px;
        position: absolute;
        top: 44px;
        width: 40px;
        left: calc(50% - 20px);
        transition: .4s;
        opacity: 0;
      }
      @media(max-width: $tabletLargePoint) {
        &:after {
          display: none;
        }
      }

      &:hover {
        &:after {
          background-color: rgba($darkGray, 0.24);
          opacity: 1;
        }
      }

      &.active {
        color: $mainBlue;
        border: none;

        &:after {
          background-color: $mainBlue;
          opacity: 1;
        }
      }
    }


  }
}
