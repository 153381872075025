.base-download-app-button {
  color: #292929;

  &-activator {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5px;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    color: inherit;
    padding: 10px 12px;
    outline: none !important;
    height: 40px;
    width: fit-content;
    cursor: pointer;
    background-color: $panelColor;

    &:hover {
      border-color: $mainHover;
    }

    &.base-download-app-button-activator-active {
      border-color: $mainBlue;
      box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
    }

    .icon-file_download {
      position: relative;
      transform: translateY(0);
    }

    .icon {
      font-size: 15px;
    }
  }
}