.dnd-switcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: 0.4s;
  position: relative;
  padding: 5px 12px 5px 5px;
  border-radius: 13px;
  font-size: 13px;

  p {
    white-space: nowrap;
  }

  &-online {
    background-color: rgba($mainGreen, 0.04);
    color: $mainGreen;

    &:hover {
      background-color: rgba($mainGreen, 0.08);
    }

    &:active {
      background-color: rgba($mainGreen, 0.12);
    }

    &:focus {
      background-color: rgba($mainGreen, 0.16);
    }
  }

  &-do-not-disturb {
    background-color: $mainRed;
    color: $panelColor;

    &:hover {
      background-color: rgba($mainRed, 0.92);
    }

    &:active {
      background-color: rgba($mainRed, 0.86);

    }

    &:focus {
      background-color: rgba($mainRed, 0.78);
    }

  }

  &-disabled {
    opacity: 0.7;
    cursor: default;
  }

  &-container-in-dropdown {
    width: 100%;

    .dnd-switcher {
      transition: .4s;
      display: flex;
      background-color: inherit;
      color: inherit;
      margin: inherit;
      font-size: inherit;
      padding: 0;
      padding-left: 2px;
      width: 100%;

      &:hover {
        background-color: unset;
      }
    }
  }
}