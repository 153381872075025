.softphone-dial-pad-skeleton {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  row-gap: 16px;

  &-row {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-child {
      margin-top: auto;
    }

    &:last-child {
      margin-bottom: auto;
    }
  }

  &-keyboard {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 24px;
    grid-row-gap: 16px;
  }
}