.top-bar {
  font-family: Inter;
  display: flex;
  justify-content: space-between;
  background-color: $panelColor;
  height: 46px;
  border-bottom: solid 2px #e8e9f6;
  position: relative;
  color: $darkGray;
  font-size: 14px;
  font-weight: 600;

  &.do-not-disturb {
    background: linear-gradient(269.93deg, rgba($mainRed, 0.24) -0.55%, rgba($mainRed, 0) 50.06%), #FFFFFF;
  }

  .title {
    display: flex;
    width: 27%;
    align-items: center;
    justify-content: center;

    @media(max-width: $tabletLargePoint) {
      justify-content: left;
      margin-left: 15px;
    }

    .logo {
      width: 128px;
      height: 34px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin-right: 20px;
      margin-left: 5px;

      @media(max-width: $tabletPoint) {
        margin-right: 15px;
        margin-left: 10px;
      }

      @media(max-width: $tabletSmPoint) {
        margin-left: 0;
      }
    }

    .title-text {
      font-size: 16px;
    }
  }

  .logged-in-panel {
    display: flex;
    justify-content: flex-end;
    max-width: 30%;
    margin-right: 25px;
    height: 46px;
    align-items: center;

    @media(max-width: $tabletBigPoint) {
      width: 10%;
    };

    button {
      width: 122px;

      @media(max-width: $laptopMediumPoint) {
        margin-right: 16px;
      }
    }

    .dnd-switcher {
      margin: 0 10px;
    }

    .avatar-container {
      margin-left: 13px;
      margin-right: 10px;

      .status {
        display: flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        right: -2px;
        bottom: -2px;
        width: 12px;
        height: 12px;
        border-width: 1.5px;
      }
    }

    .name-wrapper {
      display: inline-block;
      position: relative;

      .name {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        max-width: 250px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        @media(max-width: $desktopSmallPoint) {
          max-width: 190px;
        }

        @media(max-width: $laptopMediumPoint) {
          max-width: 180px;
        }

        @media(max-width: $laptopBigPoint) {
          max-width: 150px;
        }

        @media(max-width: $tabletBigPoint) {
          display: none;
        };

        @media(max-width: $laptopXSmallPoint) {
          max-width: 110px;
        }

        @media(max-width: $tabletLargePoint) {
          max-width: 200px;
        }
      }
    }

    .logged-in-dropdown-container {
      display: flex;
      align-items: center;
      height: 100%;
    }
  }
}
