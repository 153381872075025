.call-history-mobile-expanded-tab {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 0 16px;

  &-actions {
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    margin-bottom: 12px;
  }
}