.voicemail-item {
  margin-bottom: 16px;
  margin-right: 16px;

  @media(max-width: $laptopPoint) {
    margin-right: 0;
  }

  &-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    margin-left: 16px;
    flex-wrap: wrap;

    h3 {
      font-weight: 600;
      font-size: 18px;
      margin-right: 10px;
    }

    .storage-used-wrapper {
      position: relative;

      .tooltip {
        top: 35px;
        height: fit-content;
        width: 245px;
      }

      &:hover {
        .tooltip {
          display: block;
        }
      }
    }
  }

  &-content {
    margin-left: 16px;

    @media(max-width: $laptopPoint) {
      margin-left: 0;

      .no-voicemail-messages {
        margin-left: 16px;
      }
    }

    .no-voicemail-messages {
      font-weight: 500;
      font-size: 16px;
      color: $textGray;
    }
  }
}