.parking-speed-dial-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 44px;
  padding: 4px 22px;

  @include is-tablet-or-sm-mobile {
    height: 40px;
    padding: 2px 22px;
  }

  &-name {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    color: $white;
    font-size: 13px;
    line-height: 20px;
  }

  &-info {
    font-weight: 500;
    color: rgba($white, 0.68);
    font-size: 11px;
    line-height: 16px;
  }
}