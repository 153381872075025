.base-textarea {
  width: 100%;
  height: 100%;

  &.textarea-focused .base-textarea-wrapper {
    border-color: $mainBlue;
    box-shadow: 0 0 0 3px rgba($mainBlue, 0.24);
  }

  &.textarea-disabled .base-textarea-wrapper {
    background-color: $lightGray;
    color: $mainGray;
    -webkit-text-fill-color: $mainGray;
    opacity: 1;
    border: 1px solid $borderGray !important;
    box-shadow: none;
    cursor: default;

    textarea {
      cursor: inherit;
    }
  }

  .base-textarea-wrapper {
    border: 1px solid $borderGray;
    border-radius: 12px;
    height: 100%;
    width: 100%;
    transition: .4s;
    max-height: inherit;
    cursor: text;
    background-color: $white;

    &:hover {
      border-color: $mainHover;
    }

    .vb-content {
      scrollbar-width: unset !important;

      &::-webkit-scrollbar {
        display: block !important;
      }
    }

    textarea {
      display: block;
      width: 100%;
      overflow: hidden;
      min-height: 40px;
      height: 0;
      resize: none;
      padding: 12px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: $darkGray;
      margin-right: 6px;
      white-space: pre-line;
      box-sizing: border-box;

      &:focus {
        outline: none;
      }

      &:focus::placeholder {
        color: transparent;
      }
    }
  }
}