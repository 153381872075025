.call-settings-button-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 300px;

  @include is-tablet-or-sm-mobile {
    width: 216px;
  }

  &-row {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    column-gap: 40px;

    @include is-tablet-or-sm-mobile {
      column-gap: 36px;
    }
  }
}