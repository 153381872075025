.sms-dialog-message {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding-left: 16px;
  padding-right: 20px;
  box-sizing: border-box;

  &.message-in {
    @media(max-width: $laptopPoint) {
      padding-right: 45px;
    }

    @media(max-width: $tabletSmallPoint) {
      padding-left: 12px;
    }

    .message {
      border: 1px solid rgba(60, 91, 245, 0.16);
      background: rgba(247, 249, 255, 0.50);
    }
  }

  &.message-out {
    @media(max-width: $laptopPoint) {
      padding-left: 45px;
    }


    @media(max-width: $tabletSmallPoint) {
      padding-right: 12px;
    }

    .message {
      border: 1px solid rgba(60, 91, 245, 0.20);
      background: linear-gradient(0deg, rgba(60, 91, 245, 0.04) 0%, rgba(60, 91, 245, 0.04) 100%), #F7F9FF;

      @media(max-width: $laptopPoint) {
        margin-left: auto;
      }

      .message-is-read {

      }
    }
  }

  .avatar-container {
    margin-bottom: auto;
    margin-right: 8px;
  }

  .message {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 7px 12px;
    border-radius: 12px;
    white-space: pre-wrap;
    word-wrap: break-word;
    min-width: 0;

    &-header {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      span {
        display: inline-block;
        margin-right: 6px;
      }

      .message-sender-name {
        color: $darkGray;
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
      }

      .message-send-time {
        color: $mainGray;
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
      }

      .icon {
        color: $mainBlue;
        font-size: 14px;

        &::before {
          margin: 0;
        }
      }

      .message-resend-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: $mainRed;
        font-size: 11px;
        cursor: pointer;

        .icon {
          color: inherit;
        }
      }
    }

    &-text {
      color: $darkGray;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
    }
  }
}