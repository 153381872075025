.base-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &-header {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    background-color: $mainBackgroundColor;
    overflow-y: auto;

    @media(max-width: $tabletSmPoint) {
      overflow-y: unset;
    }

    &::-webkit-scrollbar {
      width: 8px;
      height: 4px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 20px;
      background-color: #FFFFFF;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba($mainBlue,0.24);
    }

    .base-tabs-tab {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 16px;
      cursor: pointer;
      width: fit-content;
      white-space: nowrap;
      position: relative;

      &.tab-current {
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        background-color: $panelColor;

        @media(max-width: $tabletSmPoint) {
          color: $darkGray;
        }
      }

      @media(max-width: $tabletSmPoint) {
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        width: 100%;
        min-width: 0;
        color: rgba(65, 75, 128, 1);

        .icon {
          font-size: 18px;
        }
      }

      .base-tabs-tab-text {
        text-align: center;
        font-size: 18px;
        font-weight: 600;

        @media(max-width: $tabletSmPoint) {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 10px;
          font-weight: 500;
        }
      }

      .base-tabs-tab-notify {
        margin-left: 10px;

        @media(max-width: $tabletSmPoint) {
          position: absolute;
          top: 8px;
          transform: translateX(12px);
          margin-left: 0;
        }
      }
    }
  }

  &-content {
    width: 100%;
    height: calc(100% - 50px);
    padding-top: 16px;

    .base-tabs-tab-content {
      height: 100%;
    }
  }
}