.caller-id-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 5px;
  width: 240px;
  height: 50px;
  font-size: 12px;
  padding: 4px 12px;
  border: 1px solid rgba($borderGray, 1);
  font-weight: 500;
  border-radius: 12px;
  line-height: 1.5;

  @include is-tablet-or-sm-mobile {
    height: 44px;
    font-size: 11px;
  }

  &-value-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    min-width: 0;
  }

  &-value-anonymous, &-value-name {
    color: $darkGray;
  }

  &-value-name {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-value-num {
    font-size: 11px;
    color: $textGray;

    @include is-tablet-or-sm-mobile {
      font-size: 10px;
    }
  }
}