.date-picker {
  width: 402px;
  border-radius: 12px;
  background: $white;
  padding: 12px 12px 0 12px;

  @media(max-width: $tabletSmPoint) {
    width: 262px;
  }

  &-content {
    display: flex;
    width: 100%;
    margin-bottom: 10px;

    @media(max-width: $tabletSmPoint) {
      flex-direction: column;
    }

    .presets-wrapper {
      width: 35%;

      @media(max-width: $tabletSmPoint) {
        width: 100%;
      }
    }

    .calendar {
      width: 65%;

      @media(max-width: $tabletSmPoint) {
        width: 100%;
      }

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 52px;
        font-size: 18px;

        .icon {
          position: initial;
          color: $textGray;
        }

        .selected-date {
          width: 100%;
          text-align: center;
          margin: 0 5px;
          padding: 3px 0;
          border-radius: 8px;
          font-weight: 600;
          color: $darkGray;
          cursor: pointer;
          transition: background-color .1s linear;

          &:hover {
            background-color: $lightGray;
          }
        }
      }
    }

    .range {
      display: flex;
      position: absolute;
      bottom: 12px;
      align-items: center;

      @media(max-width: $tabletSmPoint) {
        position: unset;
        margin: 0 auto;
      }

      .date {
        height: 28px;
        padding: 8px 10px;
        width: fit-content;
        min-width: 87px;
        border: 1px solid $borderGray;
        border-radius: 8px;
        font-size: 12px;
        line-height: 12px;
        color: $textGray;
      }

      .icon {
        position: unset;
        width: initial;
        height: initial;
        font-size: 18px;
      }
    }
  }

  &-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border-top: 1px solid $borderGray;

    .buttons {
      display: flex;
      width: 100%;
      justify-content: flex-end;

      button {
        height: 28px;
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .calendar-btn {
    display: block;
    box-sizing: border-box;
    height: 28px;
    min-width: fit-content;
    text-align: center;
    border-radius: 8px;
    background-color: $white;
    color: $darkGray;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    outline: none;
    transition: background-color .1s linear;

    &:hover {
      background-color: $lightGray;
    }

    &.current {
      border: 2px solid $mainBlue;
      background-color: transparent;
      color: $darkGray;
      border-radius: 8px !important;
    }

    &.active {
      background-color: $mainBlue;
      color: $white !important;
    }
  }
}