.settings-tab-user {
  .settings-avatar-section {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0; // safari fix
    width: 100%;
    margin-bottom: 16px;
    column-gap: 10px;

    .avatar-container {
      margin: 0;

      .avatar {
        height: 95px;
        width: 95px;
        font-size: 38px;
        border-radius: 50%;
        background-color: $mainBlue;
        color: $panelColor;

        @media(max-width: $phoneSmallPoint) {
          height: 60px;
          width: 60px;
        }
      }
    }
  }

  .name {
    margin-bottom: 16px;
  }
}