.follow-me-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 18px;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
    height: 18px;
  }

  &-title-container {
    display: flex;
    align-items: center;
    width: 130px;
    margin-right: 8px;

    .icon {
      color: $mainGray;
      font-size: 18px;
      margin-right: 8px;

      &::before {
        margin: 0;
      }
    }

    .name {
      display: inline-block;
      font-size: 12px;
      color: $darkGray;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &-slider-container {
    margin-right: 8px;

    .base-slider {
      width: 115px;
    }
  }

  &-switch-container {
    margin-right: 8px;
  }

  &-device-session-container {
    width: 100%;
    margin-top: 8px;

    span {
      display: inline-block;
      width: 100%;
      color: #5C5C5C;
      font-weight: 500;
      font-size: 11px;
      text-align: left;
    }
  }
}