.settings-tab {
  padding-bottom: 16px;

  &-header {
    display: flex;
    align-items: center;
    color: $darkGray;

    & > .icon {
      display: none;
      font-size: 24px;
      margin-right: 8px;
      cursor: pointer;

      @media(max-width: $laptopXSmallPoint) {
        display: inline-block;
      }
    }

    .tab-name {
      font-size: 18px;
      font-weight: 600;
    }
  }

  &-content {
    margin: 16px 0;
  }

  &-actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px;
  }
}