.container-persons-collapsed {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;

  @include is-tablet-or-sm-mobile {
    row-gap: 0;

    .person-collapsed:first-child {
      border-bottom-right-radius: initial;
      border-bottom-left-radius: initial;
    }

    .person-collapsed:last-child {
      border-top-right-radius: initial;
      border-top-left-radius: initial;
    }
  }
}