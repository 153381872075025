span[class*="country-flag-"] {
  background-image: url("../images/country_flag_sprite.png");
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px;
}

.country-flag-ad {
  background-position: 0 0px;
}

.country-flag-ae {
  background-position: 0 -24px;
}

.country-flag-af {
  background-position: 0 -48px;
}

.country-flag-ag {
  background-position: 0 -72px;
}

.country-flag-ai {
  background-position: 0 -96px;
}

.country-flag-al {
  background-position: 0 -120px;
}

.country-flag-am {
  background-position: 0 -144px;
}

.country-flag-ao {
  background-position: 0 -168px;
}

.country-flag-ar {
  background-position: 0 -192px;
}

.country-flag-as {
  background-position: 0 -216px;
}

.country-flag-at {
  background-position: 0 -240px;
}

.country-flag-au {
  background-position: 0 -264px;
}

.country-flag-aw {
  background-position: 0 -288px;
}

.country-flag-ax {
  background-position: 0 -312px;
}

.country-flag-az {
  background-position: 0 -336px;
}

.country-flag-ba {
  background-position: 0 -360px;
}

.country-flag-bb {
  background-position: 0 -384px;
}

.country-flag-bd {
  background-position: 0 -408px;
}

.country-flag-be {
  background-position: 0 -432px;
}

.country-flag-bf {
  background-position: 0 -456px;
}

.country-flag-bg {
  background-position: 0 -480px;
}

.country-flag-bh {
  background-position: 0 -504px;
}

.country-flag-bi {
  background-position: 0 -528px;
}

.country-flag-bj {
  background-position: 0 -552px;
}

.country-flag-bl {
  background-position: 0 -576px;
}

.country-flag-bm {
  background-position: 0 -600px;
}

.country-flag-bn {
  background-position: 0 -624px;
}

.country-flag-bo {
  background-position: 0 -648px;
}

.country-flag-bq-bo {
  background-position: 0 -672px;
}

.country-flag-bq-sa {
  background-position: 0 -696px;
}

.country-flag-bq-se {
  background-position: 0 -720px;
}

.country-flag-br {
  background-position: 0 -744px;
}

.country-flag-bs {
  background-position: 0 -768px;
}

.country-flag-bt {
  background-position: 0 -792px;
}

.country-flag-bw {
  background-position: 0 -816px;
}

.country-flag-by {
  background-position: 0 -840px;
}

.country-flag-bz {
  background-position: 0 -864px;
}

.country-flag-ca-bc {
  background-position: 0 -888px;
}

.country-flag-ca {
  background-position: 0 -912px;
}

.country-flag-cc {
  background-position: 0 -936px;
}

.country-flag-cd {
  background-position: 0 -960px;
}

.country-flag-cf {
  background-position: 0 -984px;
}

.country-flag-cg {
  background-position: 0 -1008px;
}

.country-flag-ch {
  background-position: 0 -1032px;
}

.country-flag-ci {
  background-position: 0 -1056px;
}

.country-flag-ck {
  background-position: 0 -1080px;
}

.country-flag-cl {
  background-position: 0 -1104px;
}

.country-flag-cm {
  background-position: 0 -1128px;
}

.country-flag-cn {
  background-position: 0 -1152px;
}

.country-flag-co {
  background-position: 0 -1176px;
}

.country-flag-cr {
  background-position: 0 -1200px;
}

.country-flag-cu {
  background-position: 0 -1224px;
}

.country-flag-cv {
  background-position: 0 -1248px;
}

.country-flag-cw {
  background-position: 0 -1272px;
}

.country-flag-cx {
  background-position: 0 -1296px;
}

.country-flag-cy {
  background-position: 0 -1320px;
}

.country-flag-cz {
  background-position: 0 -1344px;
}

.country-flag-de {
  background-position: 0 -1368px;
}

.country-flag-dj {
  background-position: 0 -1392px;
}

.country-flag-dk {
  background-position: 0 -1416px;
}

.country-flag-dm {
  background-position: 0 -1440px;
}

.country-flag-do {
  background-position: 0 -1464px;
}

.country-flag-dz {
  background-position: 0 -1488px;
}

.country-flag-easter_island {
  background-position: 0 -1512px;
}

.country-flag-ec-w {
  background-position: 0 -1536px;
}

.country-flag-ec {
  background-position: 0 -1560px;
}

.country-flag-ee {
  background-position: 0 -1584px;
}

.country-flag-eg {
  background-position: 0 -1608px;
}

.country-flag-eh {
  background-position: 0 -1632px;
}

.country-flag-er {
  background-position: 0 -1656px;
}

.country-flag-es-ce {
  background-position: 0 -1680px;
}

.country-flag-es-cn {
  background-position: 0 -1704px;
}

.country-flag-es-ga {
  background-position: 0 -1728px;
}

.country-flag-es-ib {
  background-position: 0 -1752px;
}

.country-flag-es-ml {
  background-position: 0 -1776px;
}

.country-flag-es-pv {
  background-position: 0 -1800px;
}

.country-flag-es {
  background-position: 0 -1824px;
}

.country-flag-esperanto {
  background-position: 0 -1848px;
}

.country-flag-et {
  background-position: 0 -1872px;
}

.country-flag-european_union {
  background-position: 0 -1896px;
}

.country-flag-fi {
  background-position: 0 -1920px;
}

.country-flag-fj {
  background-position: 0 -1944px;
}

.country-flag-fk {
  background-position: 0 -1968px;
}

.country-flag-fm {
  background-position: 0 -1992px;
}

.country-flag-fo {
  background-position: 0 -2016px;
}

.country-flag-fr-h {
  background-position: 0 -2040px;
}

.country-flag-fr {
  background-position: 0 -2064px;
}

.country-flag-ga {
  background-position: 0 -2088px;
}

.country-flag-gb-eng {
  background-position: 0 -2112px;
}

.country-flag-gb-ork {
  background-position: 0 -2136px;
}

.country-flag-gb-sct {
  background-position: 0 -2160px;
}

.country-flag-gb-wls {
  background-position: 0 -2184px;
}

.country-flag-gb {
  background-position: 0 -2208px;
}

.country-flag-gd {
  background-position: 0 -2232px;
}

.country-flag-ge-ab {
  background-position: 0 -2256px;
}

.country-flag-ge {
  background-position: 0 -2280px;
}

.country-flag-gg {
  background-position: 0 -2304px;
}

.country-flag-gh {
  background-position: 0 -2328px;
}

.country-flag-gi {
  background-position: 0 -2352px;
}

.country-flag-gl {
  background-position: 0 -2376px;
}

.country-flag-gm {
  background-position: 0 -2400px;
}

.country-flag-gn {
  background-position: 0 -2424px;
}

.country-flag-gq {
  background-position: 0 -2448px;
}

.country-flag-gp {
  background-position: 0 -2472px;
}

.country-flag-gr {
  background-position: 0 -2496px;
}

.country-flag-gt {
  background-position: 0 -2520px;
}

.country-flag-gu {
  background-position: 0 -2544px;
}

.country-flag-gw {
  background-position: 0 -2568px;
}

.country-flag-gy {
  background-position: 0 -2592px;
}

.country-flag-hausa {
  background-position: 0 -2616px;
}

.country-flag-hk {
  background-position: 0 -2640px;
}

.country-flag-hmong {
  background-position: 0 -2664px;
}

.country-flag-hn {
  background-position: 0 -2688px;
}

.country-flag-hr {
  background-position: 0 -2712px;
}

.country-flag-ht {
  background-position: 0 -2736px;
}

.country-flag-hu {
  background-position: 0 -2760px;
}

.country-flag-id {
  background-position: 0 -2784px;
}

.country-flag-ie {
  background-position: 0 -2808px;
}

.country-flag-il {
  background-position: 0 -2832px;
}

.country-flag-im {
  background-position: 0 -2856px;
}

.country-flag-in {
  background-position: 0 -2880px;
}

.country-flag-io {
  background-position: 0 -2904px;
}

.country-flag-iq {
  background-position: 0 -2928px;
}

.country-flag-ir {
  background-position: 0 -2952px;
}

.country-flag-is {
  background-position: 0 -2976px;
}

.country-flag-it-82 {
  background-position: 0 -3000px;
}

.country-flag-it-88 {
  background-position: 0 -3024px;
}

.country-flag-it {
  background-position: 0 -3048px;
}

.country-flag-je {
  background-position: 0 -3072px;
}

.country-flag-jm {
  background-position: 0 -3096px;
}

.country-flag-jo {
  background-position: 0 -3120px;
}

.country-flag-jp {
  background-position: 0 -3144px;
}

.country-flag-kannada {
  background-position: 0 -3168px;
}

.country-flag-ke {
  background-position: 0 -3192px;
}

.country-flag-kg {
  background-position: 0 -3216px;
}

.country-flag-kh {
  background-position: 0 -3240px;
}

.country-flag-ki {
  background-position: 0 -3264px;
}

.country-flag-km {
  background-position: 0 -3288px;
}

.country-flag-kn {
  background-position: 0 -3312px;
}

.country-flag-kp {
  background-position: 0 -3336px;
}

.country-flag-kr {
  background-position: 0 -3360px;
}

.country-flag-kurdistan {
  background-position: 0 -3384px;
}

.country-flag-kw {
  background-position: 0 -3408px;
}

.country-flag-ky {
  background-position: 0 -3432px;
}

.country-flag-kz {
  background-position: 0 -3456px;
}

.country-flag-la {
  background-position: 0 -3480px;
}

.country-flag-lb {
  background-position: 0 -3504px;
}

.country-flag-lc {
  background-position: 0 -3528px;
}

.country-flag-li {
  background-position: 0 -3552px;
}

.country-flag-lk {
  background-position: 0 -3576px;
}

.country-flag-lr {
  background-position: 0 -3600px;
}

.country-flag-ls {
  background-position: 0 -3624px;
}

.country-flag-lt {
  background-position: 0 -3648px;
}

.country-flag-lu {
  background-position: 0 -3672px;
}

.country-flag-lv {
  background-position: 0 -3696px;
}

.country-flag-ly {
  background-position: 0 -3720px;
}

.country-flag-ma {
  background-position: 0 -3744px;
}

.country-flag-malayali {
  background-position: 0 -3768px;
}

.country-flag-maori {
  background-position: 0 -3792px;
}

.country-flag-mc {
  background-position: 0 -3816px;
}

.country-flag-md {
  background-position: 0 -3840px;
}

.country-flag-me {
  background-position: 0 -3864px;
}

.country-flag-mf {
  background-position: 0 -3888px;
}

.country-flag-mg {
  background-position: 0 -3912px;
}

.country-flag-mh {
  background-position: 0 -3936px;
}

.country-flag-mk {
  background-position: 0 -3960px;
}

.country-flag-ml {
  background-position: 0 -3984px;
}

.country-flag-mm {
  background-position: 0 -4008px;
}

.country-flag-mn {
  background-position: 0 -4032px;
}

.country-flag-mo {
  background-position: 0 -4056px;
}

.country-flag-mp {
  background-position: 0 -4080px;
}

.country-flag-mq {
  background-position: 0 -4104px;
}

.country-flag-mr {
  background-position: 0 -4128px;
}

.country-flag-ms {
  background-position: 0 -4152px;
}

.country-flag-mt {
  background-position: 0 -4176px;
}

.country-flag-mu {
  background-position: 0 -4200px;
}

.country-flag-mv {
  background-position: 0 -4224px;
}

.country-flag-mw {
  background-position: 0 -4248px;
}

.country-flag-mx {
  background-position: 0 -4272px;
}

.country-flag-my {
  background-position: 0 -4296px;
}

.country-flag-mz {
  background-position: 0 -4320px;
}

.country-flag-na {
  background-position: 0 -4344px;
}

.country-flag-nato {
  background-position: 0 -4368px;
}

.country-flag-nc {
  background-position: 0 -4392px;
}

.country-flag-ne {
  background-position: 0 -4416px;
}

.country-flag-nf {
  background-position: 0 -4440px;
}

.country-flag-ng {
  background-position: 0 -4464px;
}

.country-flag-ni {
  background-position: 0 -4488px;
}

.country-flag-nl {
  background-position: 0 -4512px;
}

.country-flag-no {
  background-position: 0 -4536px;
}

.country-flag-northern_cyprus {
  background-position: 0 -4560px;
}

.country-flag-np {
  background-position: 0 -4584px;
}

.country-flag-nr {
  background-position: 0 -4608px;
}

.country-flag-nu {
  background-position: 0 -4632px;
}

.country-flag-nz {
  background-position: 0 -4656px;
}

.country-flag-om {
  background-position: 0 -4680px;
}

.country-flag-pa {
  background-position: 0 -4704px;
}

.country-flag-pe {
  background-position: 0 -4728px;
}

.country-flag-pf {
  background-position: 0 -4752px;
}

.country-flag-pg {
  background-position: 0 -4776px;
}

.country-flag-ph {
  background-position: 0 -4800px;
}

.country-flag-pk {
  background-position: 0 -4824px;
}

.country-flag-pl {
  background-position: 0 -4848px;
}

.country-flag-pn {
  background-position: 0 -4872px;
}

.country-flag-pr {
  background-position: 0 -4896px;
}

.country-flag-ps {
  background-position: 0 -4920px;
}

.country-flag-pt-20 {
  background-position: 0 -4944px;
}

.country-flag-pt-30 {
  background-position: 0 -4968px;
}

.country-flag-pt {
  background-position: 0 -4992px;
}

.country-flag-pw {
  background-position: 0 -5016px;
}

.country-flag-py {
  background-position: 0 -5040px;
}

.country-flag-qa {
  background-position: 0 -5064px;
}

.country-flag-ro {
  background-position: 0 -5088px;
}

.country-flag-rs {
  background-position: 0 -5112px;
}

.country-flag-ru {
  background-position: 0 -5136px;
}

.country-flag-rw {
  background-position: 0 -5160px;
}

.country-flag-sa {
  background-position: 0 -5184px;
}

.country-flag-sb {
  background-position: 0 -5208px;
}

.country-flag-sc {
  background-position: 0 -5232px;
}

.country-flag-sd {
  background-position: 0 -5256px;
}

.country-flag-se {
  background-position: 0 -5280px;
}

.country-flag-sg {
  background-position: 0 -5304px;
}

.country-flag-sh {
  background-position: 0 -5328px;
}

.country-flag-si {
  background-position: 0 -5352px;
}

.country-flag-sindh {
  background-position: 0 -5376px;
}

.country-flag-sj {
  background-position: 0 -5400px;
}

.country-flag-sk {
  background-position: 0 -5424px;
}

.country-flag-sl {
  background-position: 0 -5448px;
}

.country-flag-sm {
  background-position: 0 -5472px;
}

.country-flag-sn {
  background-position: 0 -5496px;
}

.country-flag-so {
  background-position: 0 -5520px;
}

.country-flag-somaliland {
  background-position: 0 -5544px;
}

.country-flag-south_ossetia {
  background-position: 0 -5568px;
}

.country-flag-sr {
  background-position: 0 -5592px;
}

.country-flag-ss {
  background-position: 0 -5616px;
}

.country-flag-st {
  background-position: 0 -5640px;
}

.country-flag-sv {
  background-position: 0 -5664px;
}

.country-flag-sx {
  background-position: 0 -5688px;
}

.country-flag-sy {
  background-position: 0 -5712px;
}

.country-flag-sz {
  background-position: 0 -5736px;
}

.country-flag-tc {
  background-position: 0 -5760px;
}

.country-flag-td {
  background-position: 0 -5784px;
}

.country-flag-tg {
  background-position: 0 -5808px;
}

.country-flag-th {
  background-position: 0 -5832px;
}

.country-flag-tibet {
  background-position: 0 -5856px;
}

.country-flag-tj {
  background-position: 0 -5880px;
}

.country-flag-tk {
  background-position: 0 -5904px;
}

.country-flag-tl {
  background-position: 0 -5928px;
}

.country-flag-tm {
  background-position: 0 -5952px;
}

.country-flag-tn {
  background-position: 0 -5976px;
}

.country-flag-to {
  background-position: 0 -6000px;
}

.country-flag-tr {
  background-position: 0 -6024px;
}

.country-flag-transnistria {
  background-position: 0 -6048px;
}

.country-flag-tt {
  background-position: 0 -6072px;
}

.country-flag-tv {
  background-position: 0 -6096px;
}

.country-flag-tw {
  background-position: 0 -6120px;
}

.country-flag-tz {
  background-position: 0 -6144px;
}

.country-flag-ua {
  background-position: 0 -6168px;
}

.country-flag-ug {
  background-position: 0 -6192px;
}

.country-flag-united_nations {
  background-position: 0 -6216px;
}

.country-flag-us-hi {
  background-position: 0 -6240px;
}

.country-flag-us {
  background-position: 0 -6264px;
}

.country-flag-uy {
  background-position: 0 -6288px;
}

.country-flag-uz {
  background-position: 0 -6312px;
}

.country-flag-va {
  background-position: 0 -6336px;
}

.country-flag-vc {
  background-position: 0 -6360px;
}

.country-flag-ve {
  background-position: 0 -6384px;
}

.country-flag-vg {
  background-position: 0 -6408px;
}

.country-flag-vi {
  background-position: 0 -6432px;
}

.country-flag-vn {
  background-position: 0 -6456px;
}

.country-flag-vu {
  background-position: 0 -6480px;
}

.country-flag-ws {
  background-position: 0 -6504px;
}

.country-flag-xk {
  background-position: 0 -6528px;
}

.country-flag-xx {
  background-position: 0 -6552px;
}

.country-flag-ye {
  background-position: 0 -6576px;
}

.country-flag-yiddish {
  background-position: 0 -6600px;
}

.country-flag-za {
  background-position: 0 -6624px;
}

.country-flag-zm {
  background-position: 0 -6648px;
}

.country-flag-zw {
  background-position: 0 -6672px;
}
