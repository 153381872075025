.base-select {
  width: 100%;
  position: relative;

  .flag-icon {
    display: inline-block;
    width: 24px;
    height: 18px;
    margin-right: 7px;
  }

  .name {
    font-size: 12px;
    font-weight: 600;
    color: $darkGray;
  }

  &.size-default .selection {
    height: 40px;
  }

  &.size-small .selection {
    height: 28px;
  }

  .selection {
    .icon {
      width: 18px;
      min-width: 18px;
      height: 18px;
    }
  }

  .selection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    cursor: pointer;

    input {
      height: 100%;
      cursor: pointer;
      padding-right: 38px;

      &.has-img {
        padding-left: 38px;
      }
    }

    img {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      margin-right: 10px;
      width: 18px;
      height: 18px;
    }

    .name {
      display: inline-block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      position: absolute;
      right: 10px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px;
      width: initial;
      height: initial;
    }

    .icon.icon-expand-down {
      color: $mainGray;
    }

    .icon.icon-expand-up {
      color: $mainBlue;
    }

    .base-tooltip-activator {
      position: absolute;
      right: 35px;
    }

    .base-tooltip {
      position: absolute;
      right: 40px;
    }
  }
}