.avatar-container {
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  display: inline-block;

  .status {
    box-sizing: content-box;
    position: absolute;
    right: -2px;
    bottom: -2px;
    width: 7px;
    height: 7px;
    border-radius: 8px;
    background-color: $mainGreen;
    color: $panelColor;
    font-size: 7px;
    transition: .4s;
    display: none;

    &.do-not-disturb {
      background-color: $mainRed;
    }

    &.disabled {
      filter: brightness(0.7);
    }

    &.contact-status-unavailable {
      display: block;
      background-color: $mainGray;
    }

    &.contact-status-idle {
      display: block;
      background-color: $mainGreen;
    }

    &.contact-status-busy {
      display: block;
      background-color: $mainRed;
    }

    &:before {
      margin: 0 1px;
    }

    @media(max-width: $laptopMediumPoint) {
      display: block;
    };
  }

  &.has-status {
    .avatar.small {
      -webkit-mask: radial-gradient(circle 8px at 23px 23px,#0000 85%,#000) bottom right;
    }

    .avatar.medium {
      -webkit-mask: radial-gradient(circle 8px at 31px 32px,#0000 85%,#000) bottom right;
    }
  }

  .avatar {
    color: #f7f8ff;
    background-color: #C9C9C9;
    background-repeat: no-repeat;
    font-weight: 600;
    border-radius: 50%;

    .icon-person {
      color: $white;
    }

    &.small {
      width: 25px;
      height: 25px;

      .icon-person {
        font-size: 16px;
      }
    }

    &.medium {
      font-size: 18px;
      width: 34px;
      height: 34px;

      @media(max-width: $laptopPoint) {
        width: 26px;
        height: 26px;
        font-size: 13px;
      }

      @include tablet-media {
        width: 19px;
        height: 19px;
        font-size: 10px;
      }

      @media(max-width: $phonePoint) {
        width: 1.5em;
        height: 1.5em;
        font-size: 1.6em;
      }
    }

    &.big {
      font-size: 28px;
      width: 62px;
      height: 62px;

      @media(max-width: $laptopPoint) {
        width: 46px;
        height: 46px;
        font-size: 21px;
      }

      @include tablet-media {
        width: 34px;
        height: 34px;
        font-size: 15px;
      }

      @media(max-width: $phonePoint) {
        width: 2.3em;
        height: 2.3em;
        font-size: 1.5em;
      }
    }

    &.extra-big {
      font-size: 28px;
      width: 72px;
      height: 72px;

      @media(max-width: $laptopPoint) {
        width: 54px;
        height: 54px;
        font-size: 21px;
      }

      @include tablet-media {
        width: 40px;
        height: 40px;
        font-size: 15px;
      }

      @media(max-width: $phonePoint) {
        width: 3em;
        height: 3em;
        font-size: 1.5em;
      }
    }

    &.exists-avatar {
      background-size: cover;
      background-position: center;
    }

    &.generated-avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: none;
      text-align: center;
      color: #ffffff;
    }
  }
}

